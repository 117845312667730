@import '../../../assets/scss/variables.scss';
.videoModal{
    margin: 0 auto ;
    max-width: 916px;
    position: relative;
    
    @include max-screen($tablet) {
        margin: 24px auto 0; 
     }
    &:after{
        content: "";
        background: rgba($color-black, 0.10);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
    }
    .videoPlay{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        svg{
            backdrop-filter: blur(8px);
            border-radius: 100%;
            @include max-screen($mobile) {
               width: 40px;
               height: 40px;
             }
        }
    }
    .shareIcon{
        position: absolute;
        top: 20px;
        right: 20px;
        svg{
            @include max-screen($mobile) {
                width: 20px;
                height: 20px;
              }
        }
        
    }
    video{
        width: 100%;
        border-radius: 8px;
        display: block;
        &::-webkit-media-controls-panel{
            background-image: linear-gradient(transparent, transparent) !important;
        }
        &::-webkit-media-controls-overlay-play-button {
            opacity: 0;
          }
    }
}