@import '../../../../assets/scss/variables.scss';
.foreclosures {
    width: 100%;

    .row {
        gap: 15px;

        &__col {
            width: calc(16.66% - 13px);
            @include max-screen($tablet) {
                width: calc(33.3% - 12px);
            }
            @include max-screen($mobile) {
                width: calc(50% - 12px);
            }
            @include max-screen($smobile) {
                width: 100%;
            }
        }
    }
}