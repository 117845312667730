@import '../../assets/scss/variables.scss';

.createLead {
    .headerIcon {
        position: relative;
        z-index: 2;
        margin-bottom: 25px;

        &:before,
        &:after {
            content: "";
            width: 208px;
            height: 208px;
            display: block;
            position: absolute;
            top: -81px;
            left: -86px;
            z-index: 1;
            pointer-events: none;
            background-color: var(--color-white);

        }

        &:after {
            @include background-image(animated-circle, gif, center center, no-repeat, 200px);
            width: 216px;
            height: 216px;
            mix-blend-mode: difference;
            opacity: 5%;

        }

        svg {
            position: relative;
            z-index: 2;
            width: 48px;
            height: 48px;
            padding: 10px;
        }

    }

    &__header {
        margin-bottom: 20px;
        position: relative;
        z-index: 3;

        h3 {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text);
            margin-bottom: 4px;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            color: var(--color-textLight);
        }
    }
    &__action {
        margin-top: 32px;
    }
    .mainCol {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        &__half {
            width: 50%;
            padding: 0 10px;

            @include max-screen($mobile) {
                width: 100%;
            }
        }
    }


  :global{
    .inputSelect, .inputGroup{
        margin-bottom: 16px;
      }
  } 
}