@import '../../assets/scss/variables.scss';

    .propertySearch {
        width: 100%;
        margin:14px 0 7px;
        gap: 16px;
        padding: 0 15px;
        
        @include max-screen($desktop-large) {
            gap: 4px;
        }
       
      &__searchBar {
        border: 1px solid var(--color-border);
        background-color: var(--color-white);
        box-shadow: $shadow;
        border-radius: 8px;
        width: 100%;
       
        @include max-screen($mobile) {
            flex-direction: column;
          }
        :global .search{
            width: 100%;
        }
        
        }
        &__filter{
            @include max-screen($mobile) {
                width: 100%;
                border-top: 1px solid var(--color-border);
              }
            ul{
                margin-top: -2px;
                position: relative;
                 border-left: 1px solid var(--color-gray);
                 padding: 0 8px;
                 @include max-screen($mobile) {
                    margin-top: 0;
                    position: relative;
                    border-left: 0;
                    padding: 5px 10px;
                  }
                li{
                    span{
                        font-size: 12px;
                        font-weight: 500;
                        color: var(--color-grayLight);
                        padding: 3px 15px;
                        border-radius: 55px;
                        height: 22px;
                        white-space: nowrap;
                        @include max-screen($desktop-m) {
                            padding: 3px 7px;
                         }
                        @include max-screen($mobile) {
                            padding: 1px 5px;
                         }
                        &.active{
                            background-color: var(--color-blue-pills-bg);
                            color: var(--color-blue-pills-text);
                        }
                    }
                }
            }
        }

      
        .info {
            color: var(--color-grayLight);
        }

        :global {
            .inputSelect {
                .MuiAutocomplete-root {
                    &.Mui-expanded {
                        .MuiInputBase-root {
                            box-shadow: $shadow7;
                        }

                    }
                }

                .MuiInput-input {
                    color: var(--color-text);
                    font-size: 16px;
                    font-weight: 500;

                    &::placeholder {
                        color: var(--color-text);
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
  .searchFilterList{
        gap: 3px;
        padding-top: 12px;
        border-top: 1px solid var(--color-borderDark);
        margin-top: 12px;
      span {
            border-radius: 8px;
            display: flex;
            align-items: center;
            gap: 7px;
            justify-content: center;
            border: 1px solid var(--color-border);
            font-size: 14px;
            padding: 1px 10px;
            cursor: pointer;
            background-color: var(--color-bgLight);
            color: var(--color-textLight);
            font-weight: 500;
           
            svg {
                width: 15px;
                min-width: 15px;
                color: var(--color-gray);
            }
        }
        
    }