@import '../../assets/scss/variables.scss';
.usages{
    &__header{
        padding-bottom: 24px;
        border-bottom: 1px solid var(--color-border);
        margin-bottom: 24px;
        h3{
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text);
        }
        p{
            font-size: 14px;
            color: var(--color-textLight);
        }
    }
    &__credits{
        border-radius: 12px;
        border: 1px solid var(--color-border);
        background: var(--color-white);
        box-shadow: $shadow;
        padding: 24px;
        margin-bottom: 24px;
        gap: 24px;
        .credits{
            width: 160px;
            &__info{
                text-align: center;
                margin-top: -15px;
                p{
                font-size: 12px;
                color: var(--color-textLight);
                }
                h3{
                    font-size: 24px;
                    font-weight: 600;
                    color: var(--color-text);
                }
            }
        }
        .creditsText{
            width: calc(100% - 184px);
            
            &__top{
                margin-bottom: 10px;
                p{
                    font-size: 14px;
                    color: var(--color-text);
                    }
                    h4{
                        font-size: 18px;
                        font-weight: 600;
                        color: var(--color-text);
                    }
            }
            &__info{
               &:not(:last-child){
                margin-bottom: 10px;
               }
                p{
                    font-size: 12px;
                    color: var(--color-textLight);
                    }
                    h5{
                        font-size: 16px;
                        font-weight: 600;
                        color: var(--color-text);
                    }
            }
        }
    }
    &__switch{
        gap: 8px;
        p{
            font-size: 14px;
            color: var(--color-textLight);
            }
            h5{
                font-size: 14px;
                font-weight: 500;
                color: var(--color-button);
            }
    }
    &__usages{
        padding: 12px 20px;
        border-bottom: 1px solid var(--color-border);
        background: var(--color-white);
        margin-bottom: 24px;
        gap: 10px;
        @include max-screen($tablet) {
          justify-content: center;
          text-align: center;
         }
        p{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 5px;
            font-size: 14px;
            color: var(--color-textLight);
            @include max-screen($tablet) {
                justify-content: center;
                text-align: center;
                flex-direction: column;
                width: 100%;

               }
            strong{
                color: var(--color-button);
            }
          
         
        }
    }
}