@import '../../assets/scss/variables.scss';

.register {
    padding: 100px 0;
    background-color: var(--color-white);

    @include max-screen($tablet) {
        padding: 15px 0;
    }
    &.addMoney {
        width: 100%;
        padding: 0;
    }
    &__close {
        svg {
            margin: -20px 0 20px;
            cursor: pointer;
        }
    }

    &__info {
        gap: 64px;

        @include max-screen($tablet) {
            gap: 24px;
        }

        .registerInfo {
            width: calc(50% - 32px);
            position: relative;
            z-index: 2;

            @include max-screen($tablet) {
                width: 100%;
            }

            &::after {
                content: "";
                @include background-image(pattern-square, svg, center center, no-repeat, cover);
                position: absolute;
                width: 336px;
                height: 336px;
                z-index: -1;
                pointer-events: none;
                top: -110px;
                left: -110px;
            }

            p {
                font-size: 14px;
                color: var(--color-gray);
            }

            h2 {
                font-size: 48px;
                font-weight: 600;
                color: var(--color-text);
            }



            ul {
                margin: 40px 0;

                li {
                    display: flex;
                    gap: 16px;

                    figure {
                        @include round-image(48px);
                        border-radius: 28px;
                        border: 8px solid var(--color-blueLight);
                        background: var(--color-blueBorder);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: var(--color-primary);

                        svg {
                            width: 24px;
                            height: 24px;
                        }
                    }

                    h3 {
                        font-size: 20px;
                        font-weight: 600;
                        color: var(--color-text);
                        margin: 10px 0 8px;
                    }


                }
            }

            &.thanks {
                &::after {
                    display: none;
                }

                h2 {
                    font-size: 36px;
                    font-weight: 600;
                    margin-bottom: 20px;
                    color: var(--color-text);
                }

                p {
                    font-size: 18px;
                    margin-bottom: 15px;
                    color: var(--color-textLight);

                    strong {
                        color: var(--color-text);
                    }
                }
            }
        }

        .formRight {
            width: calc(50% - 32px);

            @include max-screen($tablet) {
                width: 100%;
            }
            &__text {
                text-align: right;
                p {
                    font-size: 16px;
                    color: var(--color-gray);
                    margin-top: 24px;
                }
            }
        }
    }

    .cardRow {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        width: 100%;
        margin-bottom: 16px;

        &__col {
            width: calc(100% - 180px);

            @include max-screen($mobile) {
                width: 100%;
            }
        }

        &__col3 {
            width: 163px;

            @include max-screen($mobile) {
                width: 100%;
            }
        }

        &__full {
            width: 100%;
            padding: 8px 0;
        }
    }

    .trial {
        margin-bottom: 10px;
    }

    .trial,
    .total,
    .totalRow {
        width: 100%;

        h5 {
            font-size: 16px;
            font-weight: 600;
            color: var(--color-text);
        }

        p {
            font-size: 14px;
            color: var(--color-gray);
        }

        .right {
            text-align: right;
        }
    }

    .total {
        p {
            font-size: 16px;
            color: var(--color-text);
        }
    }



    .success {
        text-align: center;
        position: relative;

        &:after {
            content: "";
            @include background-image(confetti, gif, center center, no-repeat, cover);
            position: absolute;
            width: 160px;
            height: 160px;
            top: -50px;
            left: -50px;
            right: 0;
            z-index: 2;
        }


        figure {
            border-radius: 28px;
            border: 8px solid var(--color-greenBg);
            background: var(--color-greenLight);
            display: flex;
            width: 48px;
            height: 48px;
            margin-bottom: 16px;
            justify-content: center;
            align-items: center;
            color: var(--color-greenDark);
            position: relative;
            z-index: 1;

        }
    }
    .cardInput {
        label {
            font-size: 14px;
            font-weight: 500;
            color: var(--color-button);
            display: block;
            margin-bottom: 6px;
        }
        :global {
            .StripeElement {
                border-radius: 8px;
                padding: 13px 14px;
                border: 1px solid var(--color-borderDark);
                background: var(--color-white);
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                width: 100%;
                font-size: 16px;
                font-weight: 400;
                outline: 0;
                height: 44px;
            }
        }
    }
    .isError {
        :global {
            .StripeElement {
                border-color: var(--color-warningText);
            }
            span {
                line-height: 1.2;
                display: block;
                margin-top: 2px;
            }
        }
    }
    &__registerInfo {
        width: 100%;
        margin-bottom: 24px;
        padding-bottom: 12px;
        border-bottom: 1px solid var(--color-borderDark);
        img {
            width: 38px;
        }
        aside {
            font-size: 14px;
            line-height: 1.4;
            padding-left: 7px;
            width: calc(100% - 38px);
            strong {
                font-weight: 600;
                color: var(--color-button);
            }
            p {
                color: var(--color-textLight);
            }
        }
    }
    :global {
        .error {
            line-height: 1.2;
            display: block;
            margin-top: 3px;
        }
    }
}