@import '../../assets/scss/variables.scss';
.card {
    border-radius: 12px;
    border: 1px solid var(--color-border);
    background: var(--color-white);
    box-shadow: $shadow;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    height: 100%;
    @include max-screen($desktop-xlarge) {
        padding: 15px;
     }
  
    p {
      color: var(--color-textLight);
      font-size: 14px;
      font-weight: 500;
    }
  
    h2 {
      font-size: 36px;
      font-weight: 600;
      letter-spacing: -0.72px;
      @include max-screen($desktop-xlarge) {
        font-size: 32px;
     }
     @include max-screen($desktop-large) {
        font-size: 26px;
     }
    }
  
    &__info {
      width: 100%;
      gap: 5px;
     
    }
  }