@import '../../assets/scss/variables.scss';

.attempts {
    .headerIcon {
        position: relative;
        z-index: 2;
        margin-bottom: 15px;

        &:before,
        &:after {
            content: "";
            width: 208px;
            height: 208px;
            display: block;
            position: absolute;
            top: -81px;
            left: -86px;
            z-index: 1;
            pointer-events: none;
            background-color: var(--color-white);

        }

        &:after {
            @include background-image(animated-circle, gif, center center, no-repeat, 200px);
            width: 216px;
            height: 216px;
            mix-blend-mode: difference;
            opacity: 5%;

        }

        svg {
            position: relative;
            z-index: 2;
            width: 30px;
            height: 30px;

        }

        .flag {
            border-radius: 10px;
            border: 1px solid var(--color-border);
            background: var(--color-white);
            box-shadow: $shadow;
            display: flex;
            width: 48px;
            height: 48px;
            padding: 12px;
            justify-content: center;
            align-items: center;

        }
    }

    &__header {
        margin-bottom: 20px;
        position: relative;
        z-index: 3;

        h3 {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text);
            margin-bottom: 4px;

            span {
                font-size: 12px;
                font-weight: 400;
            }
        }

        p {
            font-size: 14px;
            font-weight: 400;
            color: var(--color-textLight);
        }
    }

    .attemptsText {
        margin-bottom: 16px;
        font-size: 16px;
        color: var(--color-text);
        border-top: 1px solid var(--color-border);  
        padding-top: 12px;
    }
    .creditsUpgrade{
        padding: 12px 0;
        background: var(--color-white);
        gap: 10px;
        @include max-screen($smobile) {
           flex-wrap: wrap;
           justify-content: center;
        }
         .info{
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
         }
        p{
            font-size: 14px;
            color: var(--color-textLight);

         
            strong{
                color: var(--color-button);
            }
           
         
        }
    }
    .switch {
        gap: 8px;
        margin-bottom: 16px;

        h5 {
            font-size: 14px;
            font-weight: 500;
            color: var(--color-button);
        }

        p {
            font-size: 14px;
            font-weight: 400;
            color: var(--color-textLight);
        }
    }

    &__action {
        margin-top: 32px;
    }

    .step2Button {
        @include max-screen($mobile) {
            margin-top: 15px;
        }
    }

    h4 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
        color: var(--color-button);
    }

    .step2 {
        :global {
            .inputSelect {
                margin-bottom: 10px;
            }
        }
    }

    .mainCol {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        &__half {
            width: 50%;
            padding: 0 10px;

            @include max-screen($mobile) {
                width: 100%;
            }
        }

    }

    .campaignCreated {
        text-align: center;
        position: relative;

        &:after {
            content: "";
            @include background-image(confetti, gif, center center, no-repeat, cover);
            position: absolute;
            width: 160px;
            height: 160px;
            top: -50px;
            left: 0;
            margin: 0 auto;
            right: 0;
            z-index: 2;
        }


        figure {
            border-radius: 28px;
            border: 8px solid var(--color-greenBg);
            background: var(--color-greenLight);
            display: flex;
            width: 48px;
            height: 48px;
            justify-content: center;
            align-items: center;
            margin: 0 auto 16px;
            color: var(--color-greenDark);
            position: relative;
            z-index: 1;

        }
    }



}