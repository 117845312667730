@import '../../assets/scss/variables.scss';
.home{
    background-color: var(--color-white);
        position: relative;
        z-index: 1;
        overflow: hidden;
        font-family: $font-poppins;
   
   
}
