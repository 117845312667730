@import '../../assets/scss/variables.scss';
.security{
    &__header{
        padding-bottom: 24px;
        border-bottom: 1px solid var(--color-border);
        margin-bottom: 24px;
        h3{
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text);
        }
        p{
            font-size: 14px;
            color: var(--color-textLight);
        }
    }
    &__form{
        .input{
            border-bottom: 1px solid var(--color-border);
            padding-bottom: 20px;
            margin-bottom: 20px;
            max-width: 820px;
            @include max-screen($stablet) {
                border-bottom:0;
                padding-bottom: 0;
                margin-bottom: 16px;
             }
            :global{
                .inputGroup{
                    display: flex;
                    gap: 0 32px;
                    flex-wrap: wrap;
                    @include max-screen($stablet) {
                        gap: 0;
                     }

                .label{
                    width: 280px;
                    color: var(--color-button);
                    font-weight: 600;
                    @include max-screen($stablet) {
                        width: 100%;
                     }
                }
                .inputGroup__input{
                    width: calc(100% - 312px);
                    max-width: 512px;
                    @include max-screen($stablet) {
                        width: 100%;
                        max-width: 100%;
                     }
                }
                .hint, .error{
                    padding-left: 312px;
                    width: 100%;
                    max-width: 840px;
                    @include max-screen($stablet) {
                        padding-left: 0;
                     }
                }
                }
            }
        }
        .button{
            gap: 12px;
        }
    }
}