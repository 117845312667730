@import '../../assets/scss/variables.scss';

.cards {
    gap: 10px;
    width: 100%;
  
    &__col {
      width: calc(25% - 8px);
      @include max-screen($tablet) {
        width: calc(50% - 8px);
     }
     @include max-screen($mobile) {
        width: calc(100% - 8px);
     }
    }
  }
  .tableTop {
    margin: 20px 0 15px;
  
    &__right {
      gap: 5px;
      @include max-screen($mobile) {
        width: calc(100% - 5px);
        margin-top: 15px;
     }
  
      p {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }