@import '../../../../assets/scss/variables.scss';

.contactInfo {
    background-color: var(--color-active);
    padding: 30px 30px 0;
    @include max-screen($tablet) {
        padding: 15px 15px 0;
    }

    &__headerRight {
        gap: 8px;
    }

    &__list {
        h2 {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text);
        }
    }

    &__listMain {
        margin-top: 24px;
        max-height: 120px;
        overflow-y: auto;
        
    }

    &__phoneList {
        width: 70%;
        @include max-screen($desktop-large) {
            width: 100%;
        }
    }

    &__emailList {
        width: 30%;
        @include max-screen($desktop-large) {
            width: 100%;
        }
    }
    &__box {
        gap: 25px;
        width: 33.3%;
        margin-bottom: 24px;
        @include max-screen($desktop) {
            width: 50%;
            gap: 15px;
        }
        @include max-screen($mobile) {
            width: 100%;
            gap: 10px;
        }
        &.emailBox{
            width: 100%;
            @include max-screen($desktop-large) {
                width: 33.3%;
            }
            @include max-screen($desktop) {
                width: 50%;
            }
            @include max-screen($mobile) {
                width: 100%;
            }
        }

        figure {
            color: var(--color-text);
            display: flex;
            gap: 10px;
            align-items: center;
            white-space: nowrap;
            label{
            font-size: 14px;
            font-weight: 500;
            }
            svg {
                min-width: 18px;
                color: var(--color-button);
            }
        }

        figcaption {
            a {
                font-size: 14px;
                color: var(--color-black);
                text-decoration: none;
                display: inline-flex;
                align-items: center;
                gap: 10px;
                word-wrap: break-word;
                word-break: break-all;
            }

            p {
                font-size: 12px;
                color: var(--color-black);
            }
        }

    }

    &__email {
        white-space: normal !important;
        word-wrap: break-word;
        word-break: break-all;
    }

    &__blueTick {
        min-width: 12px;
        display: inline-flex;

    }

    &__dotMenu {
        gap: 8px;

        :global {
            .copy-icon {
                width: 12px;
                height: 12px;
                display: inline-flex;
            }

            .button {
                min-height: 0;
            }

            .button__icon svg {
                width: 14px;
                height: 14px;
            }
        }
    }
    &__blared{
        filter: blur(2px);
        .contactInfo__listMain{
            overflow: hidden;
        }
       
    }
    &.nodata{
        height: 315px;
        position: relative;
        .contactInfo__list{
            h2{
                filter: blur(2px);
            }
        }
      
        :global{
            .noData{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                pointer-events: none;
            }
        }
    }
}
