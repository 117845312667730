.coupon {
    margin: 15px 0;

    &__inner {
        gap: 10px;
    }

    &__input {
        max-width: 200px;
        position: relative;
    }
    &__inputClose{
        position: absolute;
        right: 10px;
        top: 36px;
        cursor: pointer;
        svg{
            color: var(--color-gray);
            width: 16px;
        }
    }

    &__btn {
        padding-top: 29px;
        min-width: 100px;
    }

    &__status {
        font-size: 14px;
        width: 100%;
        color: var(--color-error);
        padding-left: 5px;
        padding-top: 5px;
        gap: 5px;
        margin-top: -15px;
        svg{
            width: 20px;
        }

        &.applied {
            color: var(--color-green);
            margin: 0;
        }
    }

}