@import '../../assets/scss/variables.scss';
.setting{
    &__header{
        border-bottom: 1px solid var(--color-border);
        min-height: 0 !important;
        margin-bottom: 32px;
        :global{
            .MuiTabs-flexContainer{
                gap: 12px;
            }
            .MuiButtonBase-root{
                padding: 0px 4px 12px 4px;
                min-width: 0;
                min-height: 0;
                font-size: 14px;
                font-weight: 600;
                color: var(--color-gray);
                font-family: $font-family-base;
                letter-spacing: 0;
                text-transform: none;
                &.Mui-selected{
                    color: var(--color-blue);
                }
                &.Mui-disabled{
                    pointer-events: all !important;
                }
            }
            .MuiTabs-indicator{
                background-color:var(--color-blue);
            }
        }
    }
   
}