@import '../../../../assets/scss/variables.scss';

.mortgage {
    width: 100%;
    .table{
        margin-bottom: 32px;
        h2{
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text);
            margin-bottom: 18px;
            display: inline-flex;
            gap: 10px;
            align-items: center;
            span {
                border-radius: 6px;
                border: 1px solid var(--color-borderDark);
                background: var(--color-white);
                box-shadow: $shadow;
                display: inline-flex;
                padding: 5px;
                color: var(--color-textLight);
                font-size: 12px;
                line-height: 1;
            }
        }
    }
}