@import '../../assets/scss/variables.scss';

.buyList {
    padding: 53px 0 88px;
    @include max-screen($tablet) {
        padding: 15px 0;
    }
    &__header {
        margin-bottom: 24px;

        h2 {
            font-size: 36px;
            font-weight: 600;
            margin-bottom: 20px;
            color: var(--color-text);
            @include max-screen($tablet) {
                font-size: 26px;
                margin-bottom: 5px;
             }
        }

        p {
            font-size: 18px;
            color: var(--color-textLight);
            @include max-screen($tablet) {
                font-size: 16px;
             }
        }
    }

    &__info {
        gap: 64px;
        @include max-screen($tablet) {
            gap: 24px;
         }
        .buyInfo {
            width: calc(50% - 32px);
            @include max-screen($tablet) {
                width: 100%;
            }
            ul {
                li {
                    display: flex;
                    gap: 16px;

                    &:not(:last-child) {
                        margin-bottom: 48px;
                        @include max-screen($tablet) {
                            margin-bottom: 15px;
                         }
                    }

                    figure {
                        @include round-image(48px);
                        border-radius: 28px;
                        border: 8px solid var(--color-blueLight);
                        background: var(--color-blueBorder);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: var(--color-primary);

                        svg {
                            width: 24px;
                            height: 24px;
                        }
                    }

                    h3 {
                        font-size: 20px;
                        font-weight: 600;
                        color: var(--color-text);
                        margin: 10px 0 8px;
                    }

                    p {
                        font-size: 16px;
                        color: var(--color-textLight);
                    }
                }
            }
        }

        .buyCard {
            width: calc(50% - 32px);
            background-color: var(--color-white);
            border: 1px solid var(--color-border);
            box-shadow: $shadow5;
            border-radius: 16px;
            @include max-screen($tablet) {
                width: 100%;
            }

            &__header {
                padding: 32px;
                border-bottom: 1px solid var(--color-border);

                h2 {
                    font-size: 24px;
                    font-weight: 600;
                    color: var(--color-text);
                    display: flex;
                    gap: 8px;
                    align-items: center;
                }

                p {
                    font-size: 16px;
                    color: var(--color-textLight);
                }

                .price {
                    font-size: 60px;
                    font-weight: 600;
                    color: var(--color-text);
                    display: flex;
                    align-items: flex-start;
                    @include max-screen($tablet) {
                        font-size: 40px;
                     }
                    small {
                        font-size: 36px;
                        margin-top: 15px;
                        @include max-screen($tablet) {
                            font-size: 20px;
                         }
                    }
                }
            }

            &__info {
                padding: 32px;

                header {
                    width: 100%;

                    h3 {
                        font-size: 16px;
                        font-weight: 600;
                        color: var(--color-text);
                    }

                    p {
                        font-size: 16px;
                        color: var(--color-textLight);
                    }

                    :global .inputSelect {
                        width: 50%;
                    }
                }
                ul{
                    margin-top: 24px;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 16px;
                    li{
                        width: calc(50% - 8px);
                        font-size: 16px;
                        color: var(--color-textLight);
                        position: relative;
                        padding-left: 36px;
                        @include max-screen($tablet) {
                           width: 100%;
                        }
                        &::before{
                            content: "";
                            @include background-image(check-icon, svg, center center, no-repeat, cover);
                            width: 24px;
                            height: 24px;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                }
            }
            &__footer{
                padding: 32px;
                border-top: 1px solid var(--color-border);
            }
        }
    }
}