@import '../../../../assets/scss/variables.scss';

.linked {
    width: 100%;

    .cards {
        gap: 24px;
        margin-bottom: 32px;

        .card {
            width: calc(25% - 18px);

            @include max-screen($tablet) {
                width: calc(50% - 18px);
            }

            @include max-screen($smobile) {
                width: 100%;
            }
        }
    }
    .table{
    .tableHeading{
        margin-bottom: 18px;
        h2{
            font-size: 18px;
            font-weight: 600;
            color: var(--color-primary);
        }
        p{
            font-size: 12px;
            font-weight: 400;
            color: var(--color-textLight);
        }
    }
       
        .tableInner{
            position:relative;
            
        }
    }
}