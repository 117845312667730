@import '../../../assets/scss/variables.scss';
.meet{
    padding: 160px 16px 117px;
    font-family: $font-poppins;
    color: var(--color-white);
    background: var(--Brand-Background-gradient, radial-gradient(59.62% 59.62% at 50% 33.89%, #2F0069 0%, #130144 27.95%, #000 100%));
    @include max-screen($tablet) {
        padding: 80px 16px;
        background: radial-gradient(271.76% 58.7% at 50.13% 43.16%, #2F0069 0%, #130144 27.95%, #000 100%);
     }

    &__inner{
    max-width: 1200px;
    margin: 0 auto;
    @include max-screen($mobile) {
        gap: 24px;
        justify-content: center;
     }
}
.header{
    text-align: center;
    margin-bottom: 128px;
    @include max-screen($tablet) {
        margin-bottom: 90px;
       }
    @include max-screen($mobile) {
        margin-bottom: 48px;
     }
    h2{
        font-size: 64px;
        letter-spacing: -2.56px;
        font-weight: 700;
        margin-bottom: 16px;
        @include max-screen($tablet) {
            font-size: 36px;
            letter-spacing: -1.44px;
         }
        span{
            background: linear-gradient(90deg, #6439FF 5.89%, #1BC8FF 30.06%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
        }
    }
    p{
        font-size: 20px;
        font-weight: 300;
        color: rgba($color-white, 0.8);
        @include max-screen($tablet) {
            font-size: 14px;
            color: rgba($color-white, 0.65);
         }
    }
}
.meetInfo{
    position: relative;
    &::after,  &::before{
        @include background-image(meet-bg, svg, center 60px, no-repeat, 1550px );
        content: "";
        position: absolute;
        width: 100%;
    height: 100%;
    top: 0;
    z-index: 0;
    opacity: 0.7;
    @include max-screen($mobile) {
        top: 135px;
        background-size: 830px;
        opacity: 1;
     }
    }
    &::before{
        @include background-image(meet-bgOverlay, svg, center 60px, no-repeat, 1550px );
        mix-blend-mode: color-dodge;
        z-index: 1;
        opacity: 0.3;
        @include max-screen($mobile) {
            top: 135px;
            background-size: 830px;
         }
    }
    
    &__left{
        width: 50%;
        @include max-screen($mobile) {
            width: 100%;
            justify-content: center;
            display: flex;
            flex-direction: column;
            gap: 24px;
         }
         .toggleCard{
            @include max-screen($mobile) {
                justify-content: center;
                display: flex;
             }
         }
        ul{
            display: flex;
            flex-direction: column;
            gap: 32px;
            text-align: left;
            margin-bottom: 145px;
            @include max-screen($mobile) {
                gap: 16px;
                display: inline-flex;
                margin: 0 auto 240px;
                max-width: 327px;
             }
            li{
                font-size: 18px;
                font-weight: 300;
                position: relative;
                padding-left: 32px;
                @include max-screen($mobile) {
                    font-size: 16px;
                 }
                &:before {
                    content: "";
                    background-color: rgba($color-white, 0.08);
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 24px;
                    height: 24px;
                    border-radius: 100%;
                    @include background-image(circle-check, svg, center center, no-repeat, cover);
                }
            }
        }
    }
    &__right{
        width: 50%;
        justify-content: flex-end;
        display: flex;
        @include max-screen($mobile) {
            width: 100%;
            justify-content: flex-start;
            gap: 24px;
            justify-content: center;
         }
         .toggleCard{
            @include max-screen($mobile) {
                width: 100%;
            }
         }
    }
    .toggleCardLast{
        width: 100%;
        margin-top: 20px;
        padding-left: 50%;
        @include max-screen($mobile) {
            padding-left: 0;
            margin-top: 0;
            justify-content: center;
            display: flex;
         }
    }
}
.numberSection{
    margin-top: 190px;
    @include max-screen($tablet) {
        margin-top: 90px;
       }
    @include max-screen($mobile) {
      display: none;
     }
    ul{
      li{
        width: 300px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        h2{
            font-size: 64px;
            font-weight: 700;
            letter-spacing: -2.56px;
            margin-bottom: -15px;
            @include max-screen($tablet) {
                font-size: 46px;
               }
        }
        p{
            font-size: 16px;
            font-weight: 400;
            color: rgba($color-white, 0.8);
        }
      }  
    }
}
}