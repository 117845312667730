@import '../../../assets/scss/variables.scss';

.customer {
    padding: 120px 16px 96px;
    overflow: hidden;
    background: radial-gradient(59.62% 59.62% at 50% 33.89%, #2F0069 0%, #130144 27.95%, #000 100%);
    font-family: $font-poppins;
    color: var(--color-white);
    @include max-screen($tablet) {
        padding: 40px 16px 168px;
     }
    &__inner {
        gap: 58px;
        @include max-screen($tablet) {
            gap: 48px;
         }
    }
.relative{
    position: relative;
}
    &__left {
        width: calc(40% - 29px);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include max-screen($tablet) {
            width: 100%;
         }
        .leftWrapper {
            max-width: 430px;
            width: 100%;
            @include max-screen($tablet) {
                max-width: 100%;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
             }
             &__inner{
                @include max-screen($tablet) {
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
             }
        }
      

        h2 {
            font-size: 64px;
            letter-spacing: -2.56px;
            font-weight: 700;
            margin-bottom: 16px;
            line-height: 1.5;
            max-width: 466px;
            @include max-screen($tablet) {
                font-size: 36px;
                letter-spacing: -1.44px;
             }

            span {
                background: var(--Brand-Highlight-gradient, linear-gradient(97deg, #6439FF 1.43%, #1BC8FF 48.61%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        p {
            font-size: 16px;
            font-weight: 400;
            color: rgba($color-white, 0.7);
            margin-bottom: 48px;
            max-width: 385px;
            @include max-screen($tablet) {
                font-size: 14px;
                color: rgba($color-white, 0.65);
                margin-bottom: 36px;
             }
        }
    }

    &__right {
        width: calc(60% - 29px);
        @include max-screen($tablet) {
            width: 100%;
         }
        :global {
            .slick-slide {
                padding: 0 10px;
            }
            .slick-slider{
                overflow: visible;
            }
            .slick-arrow{
                top: auto;
                bottom: -110px;
                left: 0;
                right: 0;
                color: var(--color-white) !important;
                @include round-image(52px);
                display: flex !important;
                align-items: center;
                justify-content: center;
                background-color: rgba($color-white, 0.08) !important;
                svg{
                    width: 30px;
                    height: 30px;
                }
                &.slick-next{
                    left: 70px;
                }
                &.slick-disabled{
                    opacity: 0.5;
                    cursor: not-allowed;
                }
                &::before{
                    display: none;
                }
            }
        }
    }

    .customerCard {
        border-radius: 32px;
        background: rgba($color-white, 0.08);
        padding: 24px;
        overflow: hidden;
        cursor: pointer;
      
        .playPauseBtn {
            @include round-image(52px);
            display: flex;
            align-items: center;
            cursor: pointer;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1000;
            justify-content: center;
            background: radial-gradient(108.18% 108.18% at 50.22% 50.91%, #FFF 0%, #FFF 100%);
            ;
            border: 1px solid rgba(255, 255, 255, 0.15);
            box-shadow: 0px 0px 0px 0px #EA1B1B inset;

            svg {
                path {
                    fill: rgba(60, 9, 171, 1) !important;
                    stroke: rgba(60, 9, 171, 1) !important;
                }

            }
            // pointer-events: none;

        }

        &:hover {
            .playPauseBtn {
                background: radial-gradient(108.18% 108.18% at 50.22% 50.91%, #3401A1 0%, #693CE7 100%);
                border: 1px solid rgba(255, 255, 255, 0.15);
                box-shadow: 0px 0px 0px 0px #EA1B1B inset;
                // pointer-events: none;
                svg {
                    path {
                        fill: #fff !important;
                        stroke: #fff !important;
                    }

                }
            }
        }

        .cardImg {
            width: 100%;
            overflow: hidden;
            border-radius: 22px;
            position: relative;
            height: 424px;

            img, video {
                width: 100%;
                height: 100%;
                object-fit: contain;
                &::-webkit-media-controls-panel{
                    background-image: linear-gradient(transparent, transparent) !important;
                }
                &::-webkit-media-controls-overlay-play-button {
                    opacity: 0;
                  }
            }

            &::after {
                position: absolute;
                width: 100%;
                content: "";
                height: 100%;
                top: 0;
                left: 0;
                z-index: 0;
                pointer-events: none;
                background: linear-gradient(180deg, rgba(5, 1, 14, 0.00) 54.96%, rgba(5, 1, 14, 0.56) 79.94%, rgba(5, 1, 14, 0.80) 100%);
            }

            .cardBottom {
                position: absolute;
                bottom: 0;
                z-index: 2;
                width: 100%;
                padding: 16px;
                gap: 12px;
                pointer-events: none;
             

                h5 {
                    font-size: 14px;
                    font-weight: 400;
                }

                p {
                    font-size: 12px;
                    font-weight: 300;
                    opacity: 0.8;
                }
            }
        }

        img {
            border-radius: 22px;
            width: 100%;
        }
        &.videoPlay{
            .cardImg {
                &::after {
                    display: none;
                }
            }
        }
    }
}