@import '../../../assets/scss/variables.scss';

.pricing {
    padding: 120px 16px;
    font-family: $font-poppins;
    background: $gradient7;
    color: var(--color-white);
    @include max-screen($tablet) {
        padding: 80px 16px ;
     }
    &__header {
        text-align: center;

        h2 {
            font-size: 64px;
            font-weight: 700;
            letter-spacing: -2.56px;
            margin-bottom: 16px;
            @include max-screen($tablet) {
                font-size: 36px;
             }

            span {
                background: linear-gradient(97deg, #6439FF 1.43%, #1BC8FF 48.61%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        p {
            font-size: 16px;
            font-weight: 400;
            opacity: 0.7;
        }

        .free {
            border-radius: 100px;
            display: inline-flex;
            margin: 32px 0 42px;
            padding: 10px 24px;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
            background: linear-gradient(86deg, #24164A 3.93%, #15073A 95.42%);

            span {
                background: linear-gradient(268deg, #515EFF -74.06%, #1BC8FF 137.9%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

        }

    }

    &__Cards {
        gap: 48px;
        margin-bottom: 48px;
        @include max-screen($tablet) {
            gap: 24px;
            margin-bottom: 24px;
         }
    }

    .pricingCard {
        border-radius: 32px;
        background: rgba($color-white, 0.08);
        width: 100%;
        max-width: 400px;
        display: flex;
        flex-direction: column;

        &__header {
            padding: 46px 46px 26px;
            border-bottom: 1px solid rgba($color-white, 0.1);
            @include max-screen($tablet) {
                padding:24px;
               }
            h5 {
                font-size: 20px;
                font-weight: 500;
            }

            p {
                margin: 6px 0;
                font-size: 16px;
                font-weight: 300;
                color: rgba($color-white, 0.65)
            }

            .price {
                display: flex;
                line-height: 1.2;

                span {
                    font-size: 32px;
                    color: var(--color-white);
                    font-weight: 300;
                    margin-top: 5px;
                }

                strong {
                    font-size: 58px;
                    font-weight: 600;
                }

                small {
                    font-size: 16px;
                    font-weight: 300;
                    color: rgba($color-white, 0.65);
                    margin-top: 40px;
                }
            }
        }

        &__info {
            padding: 46px;
            flex: 1;
            @include max-screen($tablet) {
                padding:24px;
               }
            ul {
                display: flex;
                flex-direction: column;
                gap: 24px;

                li {
                    font-size: 16px;
                    font-weight: 300;
                    color: var(--color-white);
                    position: relative;
                    padding-left: 32px;
                    @include max-screen($tablet) {
                        font-size: 14px;
                     }

                    &:before {
                        content: "";
                        background-color: rgba($color-white, 0.08);
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 24px;
                        height: 24px;
                        border-radius: 100%;
                        @include background-image(circle-check, svg, center center, no-repeat, cover);
                    }
                }
            }
        }

        &__btn {
            padding: 0 46px 46px;
            flex: 0;
            @include max-screen($tablet) {
                padding: 0 24px 24px;
               }
        }
    }

    &__Credit {
        background-color: rgba($color-white, 0.08);
        border-radius: 32px;
        width: 100%;
        max-width: 848px;
        margin: 0 auto;
        @include max-screen($mobile) {
          flex-direction: column;
         }

        .credit {
            padding: 24px 46px;
            width: calc(100% - 194px);
            gap: 20px;
            @include max-screen($mobile) {
                width: 100%;
                gap: 14px;
                align-items: center;
                padding: 24px;
               }

            figure {
                @include round-image(58px);
                background-color: rgba($color-white, 0.12);
                display: flex;
                align-items: center;
                justify-content: center;
                @include max-screen($mobile) {
                    @include round-image(42px);
                   }
                   svg{
                    @include max-screen($mobile) {
                        width: 24px;
                    }
                   }
            }

            figcaption {
                font-size: 16px;
                font-weight: 300;
                max-width: 370px;
                color: rgba($color-white, 0.8);
               
                @include max-screen($mobile) {
                    font-size: 14px;
                    width:  calc(100% - 56px);
                   }

                strong {
                    font-weight: 500;
                    color: var(--color-white);
                }
            }
        }

        .skip {
            width: 194px;
            background-color: rgba($color-white, 0.08);
            border-radius: 32px;
            padding: 17px 46px;
            text-align: center;
            @include max-screen($mobile) {
                width: 100%;
                padding: 16px;
               }
            p {
                font-size: 12px;
                font-weight: 300;
                color: rgba($color-white, 0.65)
            }

            h3 {
                font-size: 36px;
                font-weight: 600;

                span {
                    font-size: 16px;
                    font-weight: 300;
                    color: rgba($color-white, 0.65)
                }
            }
        }
    }
}