@import '../../assets/scss/variables.scss';

.policy {
    background-color: var(--color-white);
    padding-bottom: 96px;

    @include max-screen($tablet) {
        padding-bottom: 30px;
    }

    &__wrapper {
        max-width: 750px;
        margin: 0 auto;
        padding: 0 15px;
    }

    &__header {
        padding: 96px 15px;
        text-align: center;
        max-width: 798px;
        margin: 0 auto;

        @include max-screen($tablet) {
            padding: 30px 15px;
        }

        h3 {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 12px;
            color: var(--color-blue);
        }

        h1 {
            font-size: 48px;
            font-weight: 600;
          
            color: var(--color-text);
            letter-spacing: -0.96px;

            @include max-screen($tablet) {
                font-size: 32px;
            }
        }

        p {
            font-size: 20px;
            margin-top: 24px;
            color: var(--color-textLight);

            @include max-screen($tablet) {
                font-size: 16px;
            }
        }
    }

    &__text {

        a {
            color: var(--color-textLight);
            text-decoration: underline;
            word-wrap: break-word;

            &:hover {
                text-decoration: none;
            }
        }

        p {
            font-size: 18px;
            color: var(--color-textLight);
            margin-bottom: 20px;
            word-wrap: break-word;

            @include max-screen($tablet) {
                font-size: 16px;
            }

            b {
                color: var(--color-textLight);
            }

        }

        h2 {
            font-size: 30px;
            font-weight: 600;
            margin: 20px 0;
            color: var(--color-text);
            line-height: 1.2;

            @include max-screen($tablet) {
                font-size: 24px;
            }
            @include max-screen($mobile) {
                font-size: 20px;
            }

        }

        h3 {
            font-size: 22px;
            font-weight: 600;
            margin: 20px 0;
            color: var(--color-text);
            line-height: 1.2;

            @include max-screen($tablet) {
                font-size: 16px;
            }
            

        }

        ul {
            padding-left: 10px;
            margin-bottom: 20px;

            li {
                position: relative;
                padding-left: 20px;
                word-wrap: break-word;
                font-size: 18px;
                color: var(--color-textLight);

                @include max-screen($tablet) {
                    font-size: 16px;
                }

                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                &:before {
                    content: "" !important;
                    @include round-image(8px);
                    background-color: var(--color-textLight);
                    position: absolute;
                    left: 0;
                    top: 8px;
                }

            }
        }

        :global .custom-table {
            margin-bottom: 20px;
            .custom-table-wrapper{
                min-height: 0;
            }
            table {
                width: 700px;
                border-collapse: collapse;
                tr {
                    td, th {

                        p {
                            font-size: 16px;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    &__list {
        margin-bottom: 20px;

        li {
            &:not(:last-child) {
                margin-bottom: 5px !important;
            }

            &:before {
                border-radius: 0 !important;
            }

        }
    }

    &__listNoMargin {
        li {
            margin-bottom: 0px !important;
        }
    }

    &__toc {
        ol {
            padding-left: 0;
            counter-reset: d1 1;
            margin-bottom: 20px;

            li {
                &:before {
                    counter-increment: d1;
                    content: counter(d1, decimal) ". ";
                }

                &:first-child {
                    &:before {
                        counter-increment: d1 0;
                    }
                }
            }
        }




    }

    &__tocInfo {
        counter-reset: m1 1;

        ol {

            &>li {
                &::before {
                    display: none;
                }

                h2 {
                    padding-left: 45px;
                    position: relative;
                    margin-top: 40px;

                    &:before {
                        counter-increment: m1;
                        content: counter(m1, decimal) ". ";
                        position: absolute;
                        left: 0;
                        top: 0;
                    }

                }

                &:first-child {
                    h2 {
                        &:before {
                            counter-increment: m1 0;
                        }
                    }

                }

                ul {
                    margin-bottom: 20px;

                    li {
                        &:not(:last-child) {
                            margin-bottom: 5px !important;
                        }

                        &:before {
                            @include round-image(6px);
                            top: 11px;
                        }

                    }
                }
            }
        }


    }
}
.calculator{
    background-color: var(--color-white);
    &__header{
        padding: 30px 60px;
        max-width: 1920px;
        margin: 0 auto;
        width: 100%;
        @include max-screen($desktop-large) {
            padding:30px 45px;
        }

        @include max-screen($mobile) {
            padding:15px;
        }
        h2 {
            font-size: 30px;
            font-weight: 600;
            line-height: 1.2;
            margin-bottom: 4px;
            color: var(--color-text);
            @include max-screen($desktop-xlarge) {
                font-size: 28px;
             }
            @include max-screen($tablet) {
                font-size: 26px;
             }
             @include max-screen($mobile) {
                font-size: 22px;
             }
             span {
                color: var(--color-grayLight);
             }
        }
    }
    :global{
        h3{
            padding-left: 30px !important;
            @include max-screen($mobile) {
                padding-left:15px!important;
            }
        }
        .shadowBoxInfo{
            padding: 15px 30px !important;
            @include max-screen($mobile) {
                padding:15px!important;
            }
        }
    }
}