@import '../../../assets/scss/variables.scss';

.leadsDetails {
    background-color: var(--color-bgLight);
    width: 527px;
    box-shadow: $shadow8;
    margin: 8px 0 0 8px;
    @include max-screen($tablet) {
        width: 100%;
        margin: 0;
    }
    @include max-screen($mobile) {
        width: 100%;
        margin: 0;
        height: 100%;
    }

    &__header {
        padding: 10px 10px 10px 25px;
        gap: 5px;
        border-bottom: 1px solid var(--color-border);

        h2 {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-button);
        }

        span {
            display: inline-flex;
            width: 28px;
            height: 28px;
            align-items: center;
            justify-content: center;
            color: var(--color-grayLight);
        }
    }

    &__body {
        padding: 10px 25px;
        display: flex;
        flex-direction: column;
        height: calc(100vh - 115px);
        @include max-screen($tablet) {
            height: calc(100vh - 110px);
        }
        @include max-screen($mobile) {
            height: calc(100% - 49px);
        }
    }

    .editor {
        border-radius: 8px;
        border: 1px solid var(--color-border);
        background: var(--color-white);
        box-shadow: $shadow;

        :global {
            .rdw-editor-main{
                -webkit-overflow-scrolling : touch !important; 
                overflow: auto !important; 
                height: 100% !important; 
            }
            .DraftEditor-root, .DraftEditor-editorContainer{
                height: 100% !important; 
               
            }
            div[data-contents] {
                height: 100px;
            }
            .public-DraftEditor-content {
                min-height: 100px;
                padding: 10px;
                max-height: 150px;
                overflow: auto !important; 
                height: 100% !important; 
                -webkit-overflow-scrolling : touch !important; 
                @include max-screen($mobile) {
                   height: 100px;
                   max-height: 100px;
                }
               
            }

            .public-DraftStyleDefault-block {
                margin: 0 !important;

            }
        }
    }

    .editorBtn {
        margin-top: 10px;
    }
.campaignName{
    margin-top: 6px;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--color-button);
    span{
        white-space: nowrap;
    max-width: 212px;
    overflow: hidden;
    text-overflow: ellipsis;
    }
    a{
        color: var(--color-button);
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }
  
}
    .messageInput {
        padding: 13px 0;

        input {
            border-radius: 8px;
            border: 1px solid var(--color-border);
            background: var(--color-white);
            box-shadow: $shadow;
            padding: 8px;
            width: 100%;
            outline: none;
            font-size: 16px;
            color: var(--color-gray);
        }
    }

    .message {
        gap: 12px;
        margin: 15px 0;
        width: 100%;
        clear: both;

        &__logo {
            border: 1px solid var(--color-border);
            @include round-image(40px);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__inner {
            width: 100%;

            .header {
                margin-bottom: 5px;

                p {
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--color-button);

                    small {
                        font-size: 14px;
                        font-weight: 400;
                        display: block;
                    }
                }

                span {
                    font-size: 12px;
                    color: var(--color-textLight);
                }
            }

            .msgBody {
                padding: 10px 14px;
                font-size: 16px;
                color: var(--color-textLight);
                border-radius: 0px 8px 8px 8px;
                background-color: var(--color-active);
                border: 1px solid var(--color-border);
                width: 100%;
            }

            .msgBodyMain {
                :global {
                    .button {
                        background-color: transparent !important;
                        box-shadow: 0 0 0 !important;
                        height: 20px;
                        min-height: 20px;
                        color: var(--color-gray);
                        width: 20px;

                    }
                }
            }
        }

        &.messageSender {
            float: right;
            max-width: 345px;

            .msgBody {
                border-radius: 8px 0px 8px 8px;
                background-color: var(--color-primary);
                color: var(--color-white);
                border: 1px solid var(--color-primary);
                word-wrap: break-word;
                width: calc(100% - 20px);
                ol,
                ul {
                    padding-left: 20px;
                    list-style: auto;
                }

                ul {
                    list-style-type: disc;
                }
            }
        }
    }

    .messageScroll {
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
        flex: 1;
        padding-right: 5px;
    }
 .characterLimit{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    position: relative;
    border-radius: 100%;
    margin-right: 10px;
    transition-property: height, width;
    transition-duration: .15s;
    &__count{
        position: absolute;
        width: 22px;
        height: 22px;
        font-size: 12px;
        color: var(--color-textLight);
        display: flex;
        align-items: center;
        justify-content: center;
        transition-property: height, width;
        transition-duration: .15s;
        &::after{
            width: 22px;
            height: 22px;
            left: 0;
            top: 0;
            content: "";
            position: absolute;
            border-radius: 50%;
            border: 2px solid var(--color-border);
            transition-property: height, width, border-width;
            transition-duration: .15s;
            box-sizing: border-box;
        }
        &.red{
            :global{
                color: lighten($color-error, 15%) !important;
            }
        }
    }
    :global .MuiCircularProgress-root{
        color: var(--color-primary) !important;
    }
    :global .MuiCircularProgress-root svg{
        width: 100%;
        height: 100%;
    }
    &.orange{
        :global .MuiCircularProgress-root{
            color: darken($color-warning, 20%) !important;
        }
    }
    &.red{
        :global .MuiCircularProgress-root{
            color: lighten($color-error, 15%) !important;
        }
    }
    &.noCircle{
        & .characterLimit__count::after {
            display: none;
        }
    }
    &.bigger {
        width: 40px;
        height: 40px;

        & .characterLimit__count {
            width: 40px;
            height: 40px;

            &::after {
                width: 40px;
                height: 40px;
                border-width: 4px;
            }
        }
    }
 }
}

.loaderWrapper {
    position: relative;
    float: left;
    width: 100%;
    min-height: 50px;
}

:global {
    .commentDrawer {
        pointer-events: none;
        position: relative;
        .MuiDrawer-paper {
            background-color: transparent;
            box-shadow: 0 0 0;
            display: flex;
            justify-content: flex-end;
            border: 0;
            bottom: 0;
            
            @include max-screen($tablet) {
                width: 100%;
            }
           
        }

        &.MuiDrawer-root {
            .MuiBackdrop-root {
                background-color: transparent !important;
            }
        }
        .infinite-scroll-component__outerdiv{
            pointer-events: all;
            @include max-screen($mobile) {
                height: 100%;
            }
            .infinite-scroll-component{
                @include max-screen($mobile) {
                    height: 100% !important;
                }
            }
        }
    }
}