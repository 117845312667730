@import '../../assets/scss/variables.scss';
.footer{
    color: var(--color-white);
    background-color: var(--color-black);
    padding: 53px 39px 60px;
    @include max-screen($stablet) {
        padding: 80px 50px;
    }
     
    &__inner{
        max-width: 1360px;
        width: 100%; 
        margin: 0 auto;
        @include max-screen($stablet) {
            justify-content: center;
            flex-direction: column;
        }
        p{
            font-size: 16px;
            @include max-screen($tablet) {
                font-size: 14px;
             }
            @include max-screen($mobile) {
                width: 100%;
                text-align: center;
                margin-bottom: 20px;
             }
             a{
                display: table;
                color: var(--color-white);
                text-decoration: none;
                @include max-screen($mobile) {
                    margin: 0 auto;
                }
                &:hover{
                    color: var(--color-primary);
                }
             }
        }
        ul{
            gap: 24px;
            @include max-screen($mobile) {
                justify-content: center;
                width: 100%;
                gap: 15px;
             }
            li{
                font-size: 15px;
                    font-weight: 300;
                    cursor: pointer;
                a{
                    color: var(--color-white);
                    text-decoration: none;
                    font-size: 15px;
                    font-weight: 300;
                    @include max-screen($mobile) {
                        font-size: 14px;
                    }
                    &:hover{
                        color: var(--color-primary);
                    }
                }
            }
        }
        .copyrightMobile{
            display: none;
            color: rgba($color-white, 0.65);
            font-size: 13px;
            @include max-screen($stablet) {
                display: block;
            }
                }
    }
    .footerLeft{
        @include max-screen($stablet) {
            justify-content: center;
            display: flex;
            flex-direction: column;
            margin-bottom: 48px;
        }
        :global{
            .logo-main{
                width: 161px;
                height: 29px;
                img{
                    width: 161px;
                    height: 29px;
                }
            }
        }
        p{
            color: rgba($color-white, 0.65);
            font-size: 13px;
            margin-top: 40px;
            @include max-screen($stablet) {
                display: none;
            }
        }
    }
    .footerRight{
        @include max-screen($stablet) {
            justify-content: center;
            display: flex;
            flex-direction: column;
        }
        ul{
            gap: 48px;
            @include max-screen($mobile) {
              flex-direction: column;
              gap: 26px;
             }
            & + ul{
                margin-top: 36px;
                justify-content: flex-end;
                gap: 16px;
                @include max-screen($stablet) {
                    justify-content: center;
                    margin: 48px 0;
                    flex-direction: row;
                }
                li{
                    a{
                        width: 32px;
                        height: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    svg{
                        color: var(--color-white);
                    }
                }
            }
        }
        &__reddit{
            position: relative;
            svg{
               position: relative;
               z-index: 1;
            }
            &::after{
                content: "";
                @include round-image(20px);
                background-color: var(--color-white);
                z-index: 0;
                position: absolute;
            }
        }
    }
    &.footerWhite{
        background-color: var(--color-white);
        color: var(--color-gray);
        padding: 32px 65px;
        border-top: 1px solid var(--color-border);
        @include max-screen($desktop-large) {
            padding: 32px 35px;
        }
    
        @include max-screen($tablet) {
            padding: 15px;
        }
    
       
        .footer__inner{
            border-top: 0;
            max-width: 1800px;
            padding: 0;
        }
        .footerLeft{
            margin-bottom: 0;
            p{
                color: var(--color-gray);
                margin-top: 0;
            }
        }
        .footerRight{
            ul{
                flex-direction: row;
                li{
                    a{
                        color: var(--color-gray);
                       
                    }
                }
                & + ul{
                    li{
                        svg{
                            color: var(--color-gray);
                        }
                    }
                }
            }
        }
        .copyrightMobile{
            color: var(--color-gray);
    margin-top: 16px;
                }
    }
}

