@import '../../../assets/scss/variables.scss';


    .heroSection {
        text-align: center;
        color: var(--color-black);
        padding: 240px 83px 113px;
        @include max-screen($tablet) {
            padding: 150px 16px;
         }
         @include max-screen($mobile) {
            padding: 150px 16px 48px;
        }
        @include background-image(hero-bg, png, center 77px, no-repeat, auto 450px );
         &__btn{
            gap: 32px;
            @include max-screen($mobile) {
                flex-direction: column;
                gap: 16px;
                margin:16px 0 0;
                display: inline-flex;
            }
          :global{
            .videoBtn{
                width: 110px;
                height: 58px;
                position: relative;
                border-radius: 100px;
                font-family: $font-poppins;
                font-weight: 400;
                letter-spacing: -0.18px;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url('../../../assets/images/video-btn.png'), lightgray 50% / cover no-repeat;
                @include max-screen($mobile) {
                    width: 100%;
                    padding: 0;
                    background-repeat: no-repeat;
                    background: var(--color-white);
                    border: 1px solid var(--color-black);
                    justify-content: space-between;
                    height: 56px;
                }
                svg{
                    width: 28px;
                    height: 28px;
                }
                .button__icon{
                    @include max-screen($mobile) {
                        width: 56px;
                        height: 56px;
                        border-radius: 100%;
                        align-items: center;
                        justify-content: center;
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url('../../../assets/images/video-btn.png'), lightgray 50% / cover no-repeat;
                    }
                }
                .button__text{
                    display: none;
                    @include max-screen($mobile) {
                        display: block;
                        width: calc(100% - 56px);
                        font-size: 18px;
                    }
                }
                &::after{
                    content: "";
                    @include background-image(watch-film, svg, center center, no-repeat, cover );
                    width: 193px;
                    height: 31px;
                    position: absolute;
                    bottom: -50px;
                    right: -170px;
                    @include max-screen($mobile) {
                        display: none;
                    }
                }
            }
          }
         }
         &__discover{
            display: none;
            flex-direction: column;
            align-items: center;
            margin-top: 64px;
            gap: 12px;
            font-size: 14px;
            color: var(--color-black);
            @include max-screen($mobile) {
               display: inline-flex;
            }
         }
        &__inner {
            margin: 0 auto;
            max-width: 1271px;
            @include max-screen($mobile) {
                max-width: 340px;
            }
            ul{
                gap: 64px;
                margin:28px 0 48px;
                @include max-screen($tablet) {
                    gap: 20px;
                 }
                @include max-screen($mobile) {
                    flex-direction: column;
                    gap: 10px;
                    margin:16px 0;
                    display: inline-flex;
                }
                li{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    font-size: 18px;
                    @include max-screen($mobile) {
                        font-size: 16px;
                    }
                    svg{
                        width: 32px;
                        height: 32px;
                        background-color: var(--color-grayLight2);
                        border-radius: 100%;

                    }
                }
            }
    
            h1 {
                font-size: 95px;
                font-weight: 700;
                line-height: 120%;
                @include max-screen($desktop) {
                    font-size: 75px;
                    line-height: 1.2;
                 }
                @include max-screen($tablet) {
                    font-size: 52px;

                 }
                 @include max-screen($stablet) {
                    font-size: 36px;
                    letter-spacing: -1.44px;
                 }
                 span{
                    @include max-screen($stablet) {
                        display: block;
                        background: var(--Text-highlight, linear-gradient(90deg, #5F44FF 42.74%, #1CC8FF 81.97%));
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;

                     } 
                 }
              
            }
    
        
    
            .downIcon {
                width: 190px;
                height: 194px;
                margin: 48px auto 0;
    
            }
            .activeUser{
              gap: 18px;
              text-align: left;
              margin-bottom: 3px;
              figcaption{
                padding-top: 3px;
                h5{
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 1.3;
                    @include max-screen($mobile) {
                        font-size: 14px;
                    }
                    span{
                        color: var(--color-blueBright2);
                    }
                }
                p{
                    font-size: 16px;
                    font-weight: 400;
                    color: var(--color-gray);
                    @include max-screen($mobile) {
                        font-size: 12px;
                    }
                }
              }
            }
        }
   
   
    
    }

