@import '../../../../assets/scss/variables.scss';

.overview {
    &__rightBtn{
        gap: 3px;
    }
    .contactInfo {
        gap: 15px;
        &__box {
            width: calc(20% - 15px);
            gap: 7px;
            @include max-screen($desktop-large) {
                width: calc(25% - 12px);
            }
            
            @include max-screen($desktop) {
                width: calc(33.3% - 15px);
            }
            @include max-screen($mobile) {
                width: calc(50% - 15px);
            }
            @include max-screen($smobile) {
                width: 100%;
            }
            figure{
                width: 18px;
                color: var(--color-gray);
                svg{
                    width: 18px;
                }
            }
            figcaption{
                width: calc(100% - 18px);
                a{
                    font-size: 14px;
                    color: var(--color-black);
                    text-decoration: none;
                    display: inline-flex;
                    align-items: center;
                    gap: 10px;
                    word-wrap: break-word;
                    word-break: break-all;
                }
                p{
                    font-size: 12px;
                    color: var(--color-black);
                }
            }

        }
        &__email{
            white-space: normal !important;
            word-wrap: break-word;
            word-break: break-all;
        }
        &__blueTick{
            min-width: 12px;
            display: inline-flex;

        }
        &__dotMenu{
            gap: 8px;
            :global{
                .copy-icon{
                    width: 12px;
                    height: 12px;
                    display: inline-flex;
                }
                .button{
                    min-height: 0;
                }
                .button__icon svg{
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }
    .calculator{
        gap: 10px;
        padding-bottom: 15px;
        &__col{
            width: calc(33.3% - 7px);
            @include max-screen($mobile) {
                width: 100%;
            }
        }
        &__colFour{
            width: calc(25% - 8px);
            @include max-screen($tablet) {
                width: calc(50% - 5px);
            }
            @include max-screen($mobile) {
                width: 100%;
            }
        }
    }
}