@import '../../assets/scss/variables.scss';

.propertyCard {
    width: 100%;
    height: 100%;

    &:hover,
    &.active {
        figcaption {
            background: rgba(147, 147, 147, 0.14);
        }

    }

    &__relative {
        position: relative;
        display: flex !important;
    }

    &__call {
        position: absolute;
        left: 10px;
        bottom: 10px;
        z-index: 2;
        color: var(--color-white);
        font-size: 12px;
        font-weight: 500;
        background-color: var(--color-primary);
        padding: 2px 6px;
        border-radius: 16px;
        gap: 4px;
        min-width: 36px;
        height: 25px;
        &.status1 {
            background-color: $color-success;
        }
        &.status2 {
            background-color: $color-orange;
        }
        &.status3 {
            background-color: $color-blue-dark;
        }
        &.status4 {
            background-color: $color-red;
        }
        &.status5 {
            background-color: $color-blue-light;
        }
        &.status6 {
            background-color: $color-magenta;
        }
        &.status7 {
            background-color: $color-blue-bright;
        }

    }

    &__tag {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 2;
        background-color: var(--color-primary);
        color: var(--color-white);
        font-size: 12px;
        padding: 5px 10px;
        border-radius: 55px;
    }

    &__card {
        padding: 9px;
        height: 100%;
        display: flex;
        flex-direction: column;

        figure {
            width: 100%;
            overflow: hidden;
            position: relative;

            @include max-screen($smobile) {
                max-height: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                aspect-ratio: 16 / 9;
                object-fit: cover;
                display: block !important;
            }

            :global {
                .slick-slide {
                    padding: 0;
                }

                .slick-next {
                    right: 0;
                }

                .slick-prev {
                    left: 0;
                    z-index: 2;
                }

                .slick-arrow {
                    background-color: transparent;
                    color: $color-white;

                    &::before {
                        display: none;
                    }
                }

                .slick-disabled {
                    display: none !important;
                }
            }
        }

        figcaption {
            width: 100%;
            padding: 15px 0;
            border-radius: 0 0 12px 12px;
            background-color: var(--color-white);
            box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
            flex: 1;
            display: flex;
            flex-direction: column;

            @include max-screen($smobile) {
                width: 100%;
                max-height: 100%;
            }

            h5 {
                font-size: 14px;
                color: var(--color-button);
                font-weight: 600;
                font-family: $font-montserrat;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0 15px;
            }

            ul {
                margin-top: 20px;
                text-align: center;

                li {
                    height: 22px;
                    line-height: 1.2;
                    padding: 0 5px;
                    width: 20%;

                    &:not(:last-child) {
                        border-right: 1px solid rgba($color-black, 0.5);
                    }

                    h6 {
                        font-size: 12px;
                        font-weight: 600;
                        color: var(--color-gray);
                        font-family: $font-montserrat;
                    }

                    p {
                        font-size: 10px;
                        font-weight: 600;
                        color: var(--color-gray);
                        font-family: $font-montserrat;
                        white-space: nowrap;
                    }
                }
            }


        }
    }

    &__cardFooter {
        margin-top: 20px;
        flex: 1;
        align-items: end;
        padding: 0 15px;

        p {
            font-size: 12px;
            font-weight: 600;
            color: var(--color-textLight);
            font-family: $font-montserrat;
        }
    }

}