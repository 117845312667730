@import '../../assets/scss/variables.scss';

.manage {
    padding: 24px;

    @include max-screen($mobile) {
        padding: 15px;
    }

    &__header {
        margin-bottom: 16px;

        svg {
            width: 48px;
            height: 48px;
        }
         :global .closeDialog{
            svg {
                width: 24px;
                height: 24px;
            } 
        }
    }

    h4 {
        font-size: 18px;
        font-weight: 600;
        color: var(--color-text);
    }

    &__item {
        margin: 15px 0;
        padding: 12px 0;
        border-bottom: 1px solid var(--color-borderDark);

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__info {
        width: calc(100% - 355px);

        @include max-screen($mobile) {
            width: 100%;
            margin-bottom: 15px;
        }

        svg {
            width: 38px;
        }

        aside {
            font-size: 14px;
            line-height: 1.4;
            padding-left: 7px;
            width: calc(100% - 38px);

            strong {
                font-weight: 600;
                color: var(--color-button);
            }

            p {
                color: var(--color-textLight);
            }
        }
    }

    &__action {
        margin-left: auto;

        @include max-screen($mobile) {
            width: 100%;
        }

        button {
            min-height: 0;

            &+button {
                margin-left: 8px;
            }
        }
    }
    &__actionSpace{
        margin-left: 8px;
    }
    &__actionSpaceRight{
        margin-right: 8px;
    }
}

:global {
    .stepsDialog.manageSubscription .MuiPaper-root {
        @include min-screen($mobile) {
            max-width: 600px;
        }
    }
}