@import '../../assets/scss/variables.scss';
.listDetail{
    padding: 20px 30px;
    background-color: var(--color-white);
    @include max-screen($tablet) {
        padding: 15px;
      }
    &__header{
        padding: 0 9px;
        h2{
            font-size: 24px;
            font-weight: 600;
            color: var(--color-black);
        }
        ul{
            gap: 10px;
            margin-bottom: 10px;
            li{
                color: var(--color-grayLight);
                font-size: 14px;
                span{
                    color: var(--color-primary);
                    display: flex;
                    gap: 5px;
                    text-decoration: underline;
                }
            }
        }
        
    }
    &__list{
        margin: 15px 0;
    }
    &__card{
        width: 25%;
        @include max-screen($desktop-xlarge) {
            width: 33.3%;
          }
          @include max-screen($tablet) {
            width: 50%;
          }
          @include max-screen($mobile) {
            width: 100%;
          }
    }
    &__filter{
        :global{
            .filter-icon .button__text + .button__icon {
                position: absolute;
                left: 23px;
                top: 5px;
                svg{
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
}
.listDetailLoader{
    height: 100%;
}