@import '../../assets/scss/variables.scss';

.property {
    :global{
        .wrapper{
            padding-left: 36px;
            padding-right: 36px;
            @include max-screen($desktop) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
 
    &__top {
        background-color: var(--color-white);
        padding:20px 25px;
        position: sticky;
        top: 0;
        z-index: 9;

        @include max-screen($tablet) {
            position: static;
        }

        .propertyHeader {
            gap: 16px;
            &__leftBack{
                font-size: 14px;
                font-weight: 600;
                color: var(--color-gray);
                gap: 13px;
            }
            &__left {
                width: calc(50% - 8px);

                @include max-screen($tablet) {
                    width: 100%;
                }
                h2 {
                    font-size: 18px;
                    color: var(--color-text);
                    margin-top: 8px;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    gap: 5px;
        
                    span {
                        color: var(--color-gray);
                    }
                }
        
                p {
                    font-size: 16px;
                    margin-top: 2px;
                    color: var(--color-textLight);
                }
              
            }
&__rightDropdown, &__rightDate{
    @include max-screen($mobile) {
        width: calc(50% - 6px);
    } 

}

            &__right {
                gap: 12px;
                width: calc(50% - 8px);
                justify-content: flex-end;

                @include max-screen($tablet) {
                    width: 100%;
                    justify-content: flex-start;
                }

                :global {
                    .button {
                        @include max-screen($smobile) {
                            width: 100%;

                        }
                    }

                    .datePicker {
                        margin: 0;

                        @include max-screen($smobile) {
                            width: 100%;
                            max-width: 100%;
                        }

                        label {
                            margin: 0;
                        }

                        .MuiInputBase-root {
                            height: 40px;
                            max-width: 150px;

                            @include max-screen($smobile) {
                                width: 100%;
                                max-width: 100%;
                            }

                            .MuiInputBase-input {
                                padding: 8.5px 5px;
                                cursor: pointer;
                            }
                        }
                    }

                    .inputSelect {
                        margin-bottom: 0;
                        width: 190px;


                        label {
                            margin: 0;
                        }

                        .MuiInputBase-root {
                            height: 40px;

                            .MuiInputBase-input {
                                font-weight: 600;
                                color: var(--color-button);
                                &::placeholder {
                                    color: var(--color-button);
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__map {
        position: relative;

    }

    &__info {
        padding: 40px 0;

        .propertyDetails {
            gap: 15px;
            margin-bottom: 10px;

            .propertyBox {
                align-items: flex-start;
                align-self: stretch;
                display: flex;
                flex-direction: column;
                gap: 5px;
             

              

                @include max-screen($mobile) {
                    width: calc(50% - 15px);

                }

                h3 {
                    font-size: 18px;
                    color: var(--color-text);
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    span {
                        border-radius: 6px;
                        border: 1px solid var(--color-borderDark);
                        background: var(--color-white);
                        box-shadow: $shadow;
                        display: inline-flex;
                        padding: 5px;
                        color: var(--color-textLight);
                        font-size: 12px;
                        line-height: 1;
                    }

                    small {
                        font-size: 12px;
                        font-weight: 400;
                    }
                }

                p {
                    font-size: 14px;
                    color: var(--color-textLight);
                }

                .progress {
                    width: 100%;
                    max-width: 224px;
                    :global{
                        .MuiLinearProgress-bar{
                            background: $gradient8;
                        }
                    }
                }
                &__edit{
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    span{
                        svg{
                            width: 12px;
                            height: 12px;
                            color: var(--color-black);
                        }
                    }
                }
            }
        }
    }
}
