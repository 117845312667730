@import '../../assets/scss/variables.scss';

.upgrade {
    &__info {
        gap: 64px;
        @include max-screen($desktop-xlarge) {
            gap: 30px;
        }
        @include max-screen($desktop-large) {
            gap: 20px;
        }
        @include max-screen($tablet) {
            gap: 24px;
        }

        .upgradeInfo {
            width: calc(50% - 32px);
            position: relative;
            z-index: 2;
            @include max-screen($desktop-xlarge) {
                width: calc(50% - 15px);
            }
            @include max-screen($desktop-large) {
                width: calc(50% - 10px);
            }
            @include max-screen($tablet) {
                width: 100%;
            }

            &::after {
                content: "";
                @include background-image(pattern-square, svg, center center, no-repeat, cover);
                position: absolute;
                width: 336px;
                height: 336px;
                z-index: -1;
                pointer-events: none;
                top: -110px;
                left: -110px;
            }

            &__header {
                margin-bottom: 24px;

                h2 {
                    font-size: 36px;
                    font-weight: 600;
                    margin-bottom: 20px;
                    color: var(--color-text);

                    @include max-screen($tablet) {
                        font-size: 26px;
                        margin-bottom: 5px;
                    }
                }

                p {
                    font-size: 18px;
                    color: var(--color-textLight);

                    @include max-screen($tablet) {
                        font-size: 16px;
                    }
                }
            }

            ul {
                li {
                    display: flex;
                    gap: 16px;

                    &:not(:last-child) {
                        margin-bottom: 48px;
                        @include max-screen($desktop-large) {
                            margin-bottom: 8px;
                        }

                      
                    }

                    figure {
                        @include round-image(48px);
                        border-radius: 28px;
                        border: 8px solid var(--color-blueLight);
                        background: var(--color-blueBorder);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: var(--color-primary);

                        svg {
                            width: 24px;
                            height: 24px;
                        }
                    }

                    h3 {
                        font-size: 20px;
                        font-weight: 600;
                        color: var(--color-text);
                        margin: 10px 0 8px;
                    }

                    p {
                        font-size: 16px;
                        color: var(--color-textLight);
                    }
                }
            }
        }

        .upgradeCard {
            width: calc(50% - 32px);
            @include max-screen($desktop-xlarge) {
                width: calc(50% - 15px);
            }
            @include max-screen($desktop-large) {
                width: calc(50% - 10px);
            }
            @include max-screen($tablet) {
                width: 100%;
            }
            &.upgradeCardFull{
                width: 100%;
            }
        }
       
    }

}