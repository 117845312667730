@import '../../assets/scss/variables.scss';
.hero {
    &__inner {
        padding: 32px 60px 24px;
         max-width: 1920px;
         width: 100%;
         margin: 0 auto;
         @include max-screen($desktop-large) {
            padding: 24px 35px;
         }
         @include max-screen($tablet) {
            padding: 15px;
        }
        h1 {
            font-size: 30px;
            font-weight: 600;
            line-height: 1.2;
            margin-bottom: 4px;
            color: var(--color-text);
            @include max-screen($desktop-xlarge) {
                font-size: 28px;
             }
            @include max-screen($tablet) {
                font-size: 26px;
             }
             @include max-screen($mobile) {
                font-size: 22px;
             }
             span {
                color: var(--color-grayLight);
             }
        }

        p {
            font-size: 16px;
            color: var(--color-textLight);
            display: flex;
            align-items: center;
            gap: 5px;
            &.campaigns{
                color: var(--color-grayLight);
            }
          
        }
        .heroInfo{
            gap: 12px;
        }
        .heroButton{
            gap: 12px;
        }
    }
}