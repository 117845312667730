@import '../../../assets/scss/variables.scss';

.lead {
    padding: 120px 16px;
    background-color: var(--color-white);
    font-family: $font-poppins;
    position: relative;
    @include max-screen($stablet) {
        padding: 0 16px 48px;
     }
    &::after,  &::before {
        content: "";
        @include background-image(leads-bg, svg, right center, no-repeat, 1200px);
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        position: absolute;
        z-index: 0;
        @include max-screen($stablet) {
           display: none;
         }
    }
    &::before{
        @include background-image(leadsOverlay, svg, right center, no-repeat, 1200px);
        z-index: 1;
        mix-blend-mode: color-dodge;
    }
    

    &__inner {
        max-width: 1200px;
        margin: 0 auto;
        gap: 139px;
        @include max-screen($stablet) {
         flex-direction: column;
         gap: 48px;
         }
    }

    &__left {
        width: calc(100% - 613px);
        @include max-screen($stablet) {
            width: 100%;
            text-align: center;
            }
        h2 {
            font-size: 64px;
            font-weight: 700;
            letter-spacing: -2.56px;
            margin-bottom: 28px;
            @include max-screen($stablet) {
                font-size: 36px;
                letter-spacing: -1.44px;
                    }
        }

        p {
            font-size: 16px;
            opacity: 0.7;
            font-weight: 400;
            max-width: 400px;
            @include max-screen($stablet) {
            margin: 0 auto;
            font-size: 14px;
            opacity: 0.65;
                }
        }
    }

    &__right {
        width: 473px;
        @include max-screen($mobile) {
            width: 100%;
            }
        .leadCard {
            border-radius: 32px;
            border: 1px solid var(--color-border);
            background: var(--color-white);
            box-shadow: 5px 8px 21px 0px rgba(186, 187, 191, 0.10), 21px 32px 39px 0px rgba(186, 187, 191, 0.09), 48px 72px 52px 0px rgba(186, 187, 191, 0.05), 86px 128px 62px 0px rgba(186, 187, 191, 0.01), 134px 201px 68px 0px rgba(186, 187, 191, 0.00);
            position: relative;
            z-index: 2;

            &__header {
                padding: 26px 20px;
                background-color: #191919;
                color: var(--color-white);
                gap: 20px;
                border-radius: 32px 32px 0 0;

                figcaption {
                    h4 {
                        font-size: 20px;
                        font-weight: 400;
                        margin-bottom: 5px;
                        @include max-screen($stablet) {
                            font-size: 14px;
                                }
                    }

                    p {
                        font-size: 14px;
                        color: rgba($color-white, 0.6);
                        @include max-screen($stablet) {
                            font-size: 10px;
                                }
                    }
                }
            }

            &__info {
                padding: 36px 20px 40px;
                gap: 10px;
                align-items: center;
                justify-content: space-between;
                @include max-screen($stablet) {
                    padding: 36px 14px 40px;
                      }
                h3 {
                    font-size: 18px;
                    font-weight: 400;
                    color: var(--color-text);
                    @include max-screen($stablet) {
                        font-size: 14px;
                            }

                    small {
                        font-size: 14px;
                        color: rgba($color-black, 0.5);
                        display: block;
                        @include max-screen($stablet) {
                            font-size: 10px;
                                }
                    }
                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba($color-black, 0.6);
                    @include max-screen($stablet) {
                        font-size: 10px;
                            }
                }
            }

            &__summery {
                padding: 0px 20px 30px;
                position: relative;
                @include max-screen($stablet) {
                    padding: 0px 14px 30px;
                      }
                &::before{
                    content: "";
                    @include background-image(leads-icon, svg, center, no-repeat, cover);
                    width: 54px;
                    height: 54px;
                    position: absolute;
                    left: -41px;
                    top: -15px;
                    @include max-screen($stablet) {
                      left: auto;
                      right: 5px;
                      top: 5px;
                      width: 40px;
                      height: 40px;
                        }
                }

                h3 {
                    font-size: 16px;
                    font-weight: 400;
                    color: var(--color-text);
                    margin-bottom: 6px;
                    @include max-screen($stablet) {
                        font-size: 14px;
                            }
                }

                p {
                    border: 1px solid var(--color-border);
                    border-radius: 16px;
                    padding: 16px 14px;
                    font-size: 14px;
                    color: var(--color-text);
                    font-weight: 300;
                    @include max-screen($stablet) {
                        font-size: 10px;
                            }
                }
            }

            &__button {
                padding: 0 20px 26px;
                @include max-screen($stablet) {
                    padding: 0 14px 26px;
                      }
                :global .button {
                    border-radius: 100px;
                    border: 0.5px solid rgba(255, 255, 255, 0.30);
                    background: radial-gradient(108.18% 108.18% at 50.22% 50.91%, #145D9B 0%, #4A46FF 100%);
                    box-shadow: 0px 0px 0px 0px #EA1B1B inset;
                    &:hover{
                        background: radial-gradient(108.18% 108.18% at 50.22% 50.91%, #4A46FF 0%, #145D9B 100%);
                    }
                }
            }
        }
    }
}