
@import '../../assets/scss/variables.scss';
.thankyou{
        text-align: center;
        @include background-image(hero-bg, png, center 15%, no-repeat, auto 350px );
        background-color: var(--color-white);
        padding: 50px 0;
        min-height: calc(100vh - 158px);

        figure {
            border-radius: 28px;
            border: 8px solid var(--color-greenBg);
            background: var(--color-greenLight);
            display: flex;
            width: 48px;
            height: 48px;
            justify-content: center;
            align-items: center;
            margin: 0 auto 16px;
            color: var(--color-greenDark);
            position: relative;
            z-index: 1;
            &:after {
                content: "";
                @include background-image(confetti, gif, center center, no-repeat, cover);
                position: absolute;
                width: 160px;
                height: 160px;
                top: -70px;
                left: -60px;
                margin: 0 auto;
                right: 0;
                z-index: 2;
            }

        }
        h1{
            font-size: 95px;
            font-weight: 700;
            color: var(color-black);
            @include max-screen($desktop) {
                font-size: 85px;
            }
            @include max-screen($tablet) {
                font-size: 65px;
            }
            @include max-screen($mobile) {
                font-size: 42px;
            }
        }
        p{
            font-size: 32px;
            font-weight: 300;
            color: var(color-black);
            &:not(:last-child){
                margin-bottom: 15px;
            }
            @include max-screen($desktop) {
                font-size: 26px;
            }
            @include max-screen($desktop) {
                font-size: 20px;
            }
            @include max-screen($mobile) {
                font-size: 16px;
            }
        }
        &__button{
            gap: 31px;
            margin-top: 59px;
            @include max-screen($mobile) {
                margin-top: 20px;
                flex-direction: column;
                gap: 10px;
            }
            button{
                @include max-screen($mobile) {
                    width: 100%;
                    max-width: 250px;
                    margin: 0 auto;
                } 
            }
        }
    }
