@import '../../assets/scss/variables.scss';

.confirm {
    .headerIcon {
        position: relative;
        z-index: 2;
        margin-top: 16px;

        &:before,
        &:after {
            content: "";
            width: 208px;
            height: 208px;
            display: block;
            position: absolute;
            top: -81px;
            left: -86px;
            z-index: 1;
            pointer-events: none;
            background-color: var(--color-white);

        }

        &:after {
            @include background-image(animated-circle, gif, center center, no-repeat, 200px);
            width: 216px;
            height: 216px;
            mix-blend-mode: difference;
            opacity: 5%;

        }

        .flagBorder {
            position: relative;
            margin-top: -15px;
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 24px;
                height: 24px;
                color: var(--color-button);
                z-index: 2;
                position: relative;
            }

            &::after {
                content: "";
                position: absolute;
                z-index: 1;
                width: 100%;
                height: 100%;

            }

            &::before {
                content: "";
                position: absolute;
                z-index: 2;
                width: 100%;
                height: 100%;
                background-color: var(--color-white);
                border-radius: 10px;
                border: 1px solid var(--color-border);
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }
        }


    }

    &__header {
        position: relative;
        z-index: 3;
        margin-top: 16px;

        h2 {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 4px;
            color: var(--color-text);
        }

        p {
            font-size: 14px;
            font-weight: 400;
            color: var(--color-textLight);
        }
    }

    &__info {
        position: relative;
        z-index: 3;
        margin-top: 20px;

        ul {
            margin-bottom: 12px;

            li {
                border: 1px solid var(--color-border);
                padding: 16px;
                border-radius: 12px;
                margin-bottom: 20px;
                display: flex;
                gap: 16px;
                align-items: center;
                figure {
                    @include round-image(32px);
                    display: flex;
                    padding: 5px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 28px;
                    border: 4px solid var(--color-blueLight);
                    background: var(--color-blueBorder);
                    mix-blend-mode: multiply;
                    color: var(--color-primary);

                    svg {
                        width: 32px;
                        height: 32px;
                    }
                }

                figcaption {
                    p {
                        font-size: 14px;
                        font-weight: 400;
                        color: var(--color-textLight);
                        line-height: 1.4;
                    }
                }
            }
        }
    }
    &__success {
        text-align: center;
        position: relative;

        &:after {
            content: "";
            @include background-image(confetti, gif, center center, no-repeat, cover);
            position: absolute;
            width: 160px;
            height: 160px;
            top: -50px;
            left: 0;
            margin: 0 auto;
            right: 0;
            z-index: 2;
        }


        figure {
            border-radius: 28px;
            border: 8px solid var(--color-greenBg);
            background: var(--color-greenLight);
            display: flex;
            width: 48px;
            height: 48px;
            justify-content: center;
            align-items: center;
            margin: 0 auto 16px;
            color: var(--color-greenDark);
            position: relative;
            z-index: 1;

        }
    }
    &__footer{
        margin-top: 20px;
    }
}