@import '../../assets/scss/variables.scss';
.infoCard{
    h4{
        font-size: 16px;
        color: var(--color-text);
        margin-bottom: 10px;
        font-weight: 600;
        display: inline-flex;
            align-items: center;
            gap: 4px;
            span{
                display: inline-flex;
            }
            svg{width: 14px;
            height: 14px;
            color: var(--color-textLight);}
        
    }
    p{
        font-size: 14px;
        color: var(--color-textLight);
        word-wrap: break-word;
        &.gold{
            color: $color-warningText;
        }
        &.red{
            color: $color-error-dark;
        }
        &.green{
            color:  $color-green;
        }
        &.yellow{
            color: $color-yellow;
        }
    }


}