@import '../../assets/scss/variables.scss';
.priceCard {
    width: 100%;
    background-color: var(--color-white);
    border: 1px solid var(--color-border);
    box-shadow: $shadow5;
    border-radius: 16px;
    @include max-screen($tablet) {
        width: 100%;
    }



    .priceCardInfo {
        padding: 32px;

     
    }
    .priceCardFooter{
        padding: 32px;
        width: 100%;
        border-top: 1px solid var(--color-border);
    }
     .priceCardHeader {
            padding: 32px;
            border-bottom: 1px solid var(--color-border);
    
            
        }
        .priceCardFooterText{
            padding: 15px 32px;
            border-top: 1px solid var(--color-border);
            p{
                font-size: 14px;
                color: var(--color-gray);
            }
        }
}
