@import '../../assets/scss/variables.scss';

.createCampaign {
    .headerIcon {
        position: relative;
        z-index: 2;
        margin-bottom: 25px;
        &.spaceBottom{
            margin-bottom: 10px;
        }
        &:before,
        &:after {
            content: "";
            width: 208px;
            height: 208px;
            display: block;
            position: absolute;
            top: -81px;
            left: -86px;
            z-index: 1;
            pointer-events: none;
            background-color: var(--color-white);

        }

        &:after {
            @include background-image(animated-circle, gif, center center, no-repeat, 200px);
            width: 216px;
            height: 216px;
            mix-blend-mode: difference;
            opacity: 5%;

        }

        svg {
            position: relative;
            z-index: 2;
            width: 48px;
            height: 48px;
            padding: 10px;
        }

        .flag {
            border-radius: 10px;
            border: 1px solid var(--color-border);
            background: var(--color-white);
            box-shadow: $shadow;
            display: flex;
            width: 48px;
            height: 48px;
            justify-content: center;
            align-items: center;

        }
        .flagBorder{
            position: relative;
            margin-top: -15px;
           
            &::after{
                content: "";
                position: absolute;
                z-index: 1;
                width: 100%;
                height: 100%;
                
            }
            &::before{
                content: "";
                position: absolute;
                z-index: 2;
                width: 100%;
                height: 100%;
                background-color: var(--color-white);
                border-radius: 10px;
                border: 1px solid var(--color-border);
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }
        }

        &.orange {

            span {
                position: relative;
                z-index: 2;
                border: 8px solid var(--color-warningBg);
                background: var(--color-warning);
                color: var(--color-warningText);
                @include round-image(48px);
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    width: 24px;
                    height: 24px;
                    padding: 0;
                }
            }
        }
    }
&__total{
    color: var(--color-text);
   strong{
    font-weight: 500;
   }
   p{
    strong{
        font-weight: 600;
    }
   }
}
&__totalText{
    p{
        small{
            font-size: 14px;
        }
    }
}
&__bottomText{
    margin: 15px 0 32px;
    p{
        font-size: 12px;
        color: var(--color-button);
    }
}
    &__header {
        margin-bottom: 20px;
        position: relative;
        z-index: 3;

        h3 {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text);
            margin-bottom: 4px;

            span {
                font-size: 12px;
                font-weight: 400;
            }
        }

        p {
            font-size: 14px;
            font-weight: 400;
            color: var(--color-textLight);

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }
&__skipTrace{
    margin-top: 20px;
    border-top: 1px solid var(--color-border);
    padding-top: 10px;
}
&__skipTraceRow{
    label{
        font-size: 14px;
        font-weight: 500;
        color: var(--color-button);
    }
    h5{
        font-size: 20px;
        font-weight: 700;
        color: var(--color-button);
    }
}
  &__reSkip{
    border-bottom: 1px solid var(--color-border);
    padding: 10px 0;
  }
  &__limit {
    width: 100%;
    border-top: 1px solid var(--color-border);
    padding-top: 15px;

    @include max-screen($mobile) {
        width: 100%;
        margin-bottom: 15px;
    }

    svg {
        width: 38px;
    }

    aside {
        font-size: 14px;
        line-height: 1.4;
        padding-left: 7px;
        width: calc(100% - 38px);

        strong {
            font-weight: 600;
            color: var(--color-button);
        }

        p {
            color: var(--color-textLight);
        }
    }
    &.upgrade{
        @include max-screen($smobile) {
           flex-wrap: wrap;
        }
       
       
    }
}
&__limitBtn{
    @include max-screen($smobile) {
         padding-top: 15px;
         display: flex;
         justify-content: center;
         width: 100%;
    }
    svg {
        width: 20px;
    }
}

  

    &__action {
        margin-top: 32px;
    }
    &__actionNext{
        @include max-screen($mobile) {
            margin-top: 32px;
        }
    }

    .step2Button {
        @include max-screen($mobile) {
            margin-top: 15px;
        }
    }

    h4 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
        color: var(--color-button);
    }

  

    .mainCol {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        &__half {
            width: 50%;
            padding: 0 10px;

            @include max-screen($mobile) {
                width: 100%;
            }
        }

    }

  
}
