@import '../../assets/scss/variables.scss';
.customCarousel{
    position: relative;
    height: 100vh;
    &__arrow{
        position: fixed;
        left: -10px;
        z-index: 99;
        top: 50%;
        @include max-screen($tablet) {
            left:-5px;
        }
        svg{
            width: 100px;
            height: 100px;
            color: $color-white;
            @include max-screen($tablet) {
                width: 30px;
                height: 30px;
            }
        }
     
    }
    &__arrowRight{
        right: -10px;
        left: auto;
        z-index: 99;
        @include max-screen($tablet) {
            right: -5px;
        }
    }

}