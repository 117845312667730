@import "../../../assets/scss/variables.scss";

.sliderMain {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 80px;
  }
  @include max-screen($mobile) {
    display: none;
  }
  :global {
    .slick-slide {
      padding: 0 12px;
      height: 180px;
    }
    .rfm-marquee, 
    .rfm-initial-child-container,
    .rfm-marquee-container{
        gap: 0 24px;
    }
  }

  .sliderBox {
    border-radius: 32px;
    height: 180px;
    width: 400px;
    overflow: hidden;

    figure {
      display: block;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .sliderBoxContent {
    border-radius: 32px;
    height: 180px;
    width: 400px;
    padding: 24px;
    background: var(--color-grayBg);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;

    figure {
      display: block;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.icon {
        display: flex;
        align-items: center;
        justify-content: center;
        @include round-image(80px);
        background-color: rgba($color-black, 0.06);
        img{
          width: 44px;
          height:44px;
      }
      }

    }

    figcaption {
      font-size: 20px;
      color: var(--color-black);
      width: calc(100% - 112px);
    }
  }

  .sliderBoxBlue {
    border-radius: 32px;
    height: 180px;
    width: 400px;
    padding: 24px;
    background: $gradient2;

    h2 {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 7px;
      color: var(--color-white);
    }

    p {
      font-size: 12px;
      font-weight: 300;
      background: $gradient3;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .audio {
      border-radius: 89.567px;
      border: 3px solid rgba(111, 97, 149, 0.2);
      background: rgba(111, 97, 149, 0.2);
      backdrop-filter: blur(8.956742286682129px);
      padding: 6px 10px;
      gap: 20px;
      margin-bottom: 16px;
      figure {
        @include round-image(40px);
        background: $gradient;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-white);
      }
      figcaption {
        width: calc(100% - 60px);
        img {
          height: 12px;
        }
      }
    }
    &.sliderMapBg {
      @include background-image(
        map-slider-bg,
        png,
        center center,
        no-repeat,
        cover
      );
      display: flex;
      align-items: center;
      h3 {
        font-size: 20px;
        font-weight: 400;
        color: var(--color-white);
        max-width: 191px;
      }
    }
  }
}
