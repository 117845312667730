@import '../../../assets/scss/variables.scss';

.main {
    background-color: var(--color-white);
    font-family: $font-poppins;
    h2 {
        letter-spacing: -2.56px;
        font-size: 64px;
        font-weight: 700;
       

        @include max-screen($tablet) {
            letter-spacing: -1.44px;
            font-size: 36px;
            text-align: center;
            line-height: 1.2;
        }
    }

    .faq {
        padding: 120px 16px;
        @include max-screen($tablet) {
            padding: 80px 16px;
        }
        &__inner {
            max-width: 1200px;
            margin: 0 auto;
        }


    }

    .powered {
        padding: 60px 0 95px;
        text-align: center;
        @include max-screen($tablet) {
            padding: 80px 0;
        }
        &__img {
            max-width: 1440px;
            margin: 0 auto;
            @include max-screen($mobile) {
                margin-bottom: 48px;
            }
      
            img {
                min-width: 100%;
                max-width: 100%;
         &.desktop{
            @include max-screen($mobile) {
                display: none;
            }
         }
         &.mobile{
            display: none;
            @include max-screen($mobile) {
                display: block;
            }
         }
             

            }
        }

        &__inner {
            max-width: 700px;
            margin: 0 auto;
            padding: 0 15px;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 48px;
            color: rgba($color-black, 0.7);
            margin-top: 16px;

            @include max-screen($tablet) {
                font-size: 14px;
                font-weight: 300;
                opacity: 0.65;
            }
        }
    }

    .tabs {
        margin-top: 71px;
        display: flex;
        align-items: center;

        @include max-screen($tablet) {
            display: none;
        }

        .tabsContent {
            background: $gradient5;
            border-radius: 32px;
            width: calc(100% - 536px);
            padding: 38px 56px 38px 114px;

            @include max-screen($desktop) {
                width: calc(100% - 450px);
            }

            h3 {
                font-size: 32px;
                font-weight: 500;
                margin-bottom: 40px;
                color: var(--color-white);
                opacity: 1;
                max-width: 356px;
            }

            p {
                font-size: 16px;
                font-weight: 300;
                color: rgba($color-white, 0.7);
                    &:not(:last-child){
                        margin-bottom: 25px;
                    }
            }
        }

        :global {
            .MuiTabs-root {
                width: 621px;
                margin-right: -50px;

                @include max-screen($desktop) {
                    width: 500px;
                }

                .MuiTabs-indicator {
                    display: none;
                }

                .MuiTabs-flexContainer {
                    border-radius: 32px;
                    overflow: hidden;

                    position: relative;
                    z-index: 2;
                }
               
                .MuiButtonBase-root {
                    padding: 24px 42px 24px 32px;
                    width: 100%;
                    text-align: left;
                    max-width: 100%;
                    min-height: 0;
                    align-items: flex-start;
                    text-transform: none;
                    font-size: 16px;
                    font-weight: 400;
                    font-family: $font-poppins;
                    background-color: var(--color-white);

                    &:before {
                        content: "";
                        width: 5px;
                        height: 5px;
                        background-color: var(--color-black);
                        position: absolute;
                        left: 16px;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &:after {
                        content: "";
                        @include background-image(arrow-right-dark, svg, center, no-repeat, cover);
                        width: 24px;
                        height: 24px;
                        right: 16px;
                        top: 50%;
                        transform: translateY(-50%);
                        position: absolute;
                    }

                    &.Mui-selected {
                        background: var(--color-blueDark);
                        box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.15);
                        font-weight: 700;
                        color: var(--color-white);
                        border-radius: 32px;

                        &:before {
                            background-color: var(--color-white);
                        }

                        &:after {
                            @include background-image(arrow-right-white, svg, center, no-repeat, cover);
                        }

                        &+.MuiButtonBase-root {
                            border-radius: 32px 32px 0 0;
                        }
                    }
                }
            }
        }
    }

    .accordion {
        display: none;

        @include max-screen($tablet) {
            display: block;
            margin-top: 48px;
        }

        :global {
            .MuiPaper-root {
                box-shadow: 0 0 0;
                margin: 6px 0;
                &::before {
                    display: none;
                }

                .MuiButtonBase-root {
                    padding: 10px 16px;
                }

                .MuiAccordionSummary-expandIconWrapper {
                    color: var(--color-black);
                    width: 24px;

                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }

                &.Mui-expanded {
                    background-color: var(--color-blueDark);
                    border-radius: 32px;
                    color: var(--color-white);

                    .MuiButtonBase-root {
                        border-bottom: 1px solid rgba(255, 255, 255, 0.16);
                    }

                    .MuiAccordionSummary-expandIconWrapper {
                        color: var(--color-white);
                    }

                    .MuiAccordionSummary-content {
                        color: var(--color-white);
                        font-weight: 700;

                        &:before {
                            background-color: var(--color-white);
                        }

                    }
                }
                .MuiAccordionDetails-root{
                    padding: 24px;
                }
            }

            .MuiCollapse-root {
                background: $gradient6;
                border-radius: 0px 0px 32px 32px;

                h3 {
                    font-size: 20px;
                    font-weight: 500;
                    margin-bottom: 16px;
                    color: var(--color-white);
                    opacity: 1;
                }

                p{
                    font-size: 14px;
                    font-weight: 300;
                    opacity: 0.8;
                    color: var(--color-white);

                    &:not(:last-child) {
                        margin-bottom: 16px;
                    }
                }
            }

            .MuiAccordionSummary-content {
                color: rgba($color-black, 0.65);

                font-family: $font-poppins;
                font-size: 16px;
                font-weight: 400;
                position: relative;
                padding-left: 16px;
                padding-right: 10px;
                margin: 0;

                &:before {
                    content: "";
                    width: 5px;
                    height: 5px;
                    background-color: var(--color-black);
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
}