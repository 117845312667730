
@import '../../../../assets/scss/variables.scss';
.toggleMain{
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    z-index: 2;
    cursor: pointer;
    @include max-screen($mobile) {
       align-items: center;
       width: 100%;
     }
}
 .WaveformContainer {
    border-radius: 100px;
    background: rgba(111, 97, 149, 0.20);
    backdrop-filter: blur(10px);
    padding: 14px;
    gap: 8px; 
    width: 395px;
    height: 80px;
    border: 4px solid transparent;
    cursor: pointer;
    @include max-screen($mobile) {
       width: 100%;
       height: 60px;
      }
    &.playOn{
        border: 4px solid rgba(111, 97, 149, 0.20);
    }
    figcaption{
       p{
        font-size: 16px;
        font-weight: 400;
        white-space: nowrap;
       } 
    }
    .playButton{
        background: radial-gradient(108.18% 108.18% at 50.22% 50.91%, #3401A1 0%, #693CE7 100%);
        border:1px solid rgba(255, 255, 255, 0.15);
        box-shadow: 0px 0px 0px 0px #EA1B1B inset;
        @include round-image(52px);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: var(--color-white);
        @include max-screen($mobile) {
            @include round-image(32px);
          }
        svg{
            width: 26px;
            height: 26px;
            @include max-screen($mobile) {
                width: 20px;
                height: 20px;
              }
        }
    }
}
  
.playTime{
    font-size: 18px;
    font-weight: 400;
    min-width: 40px;
}
.Wave  {
  width: 100%;
  height: 40px;
  display: block;
  wave{
    overflow:  hidden !important;
  }
}
.wave{
    position: relative;
    width: 100%;
   width: 250px;
    .waveLength{
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
    transform: translateY(-100%);
    }
}

.none{
    display: none;
}
.hidden{
visibility: hidden;

}
.toggleCardInfo{
display: flex;
max-width: 393px;
width: 100%;
padding: 24px 32px;
flex-direction: column;
align-items: flex-start;
gap: 8px;
flex-shrink: 0;
border-radius: 19px;
border: 4px solid rgba(111, 97, 149, 0.20);
background: rgba(27, 17, 51);
backdrop-filter: blur(10px);
transition: all 0.2s;
position: absolute;
top: 104px;
@include max-screen($mobile) {
    position: static;
    max-width: 100%;
}
&::before{
    content: "";
    @include background-image(leads-icon, svg, center, no-repeat, cover);
    width: 42px;
    height: 42px;
    position: absolute;
    left: -24px;
    top: 14px;
    @include max-screen($stablet) {
      left: auto;
      right: -10px;
      top: -10px;
      width: 40px;
      height: 40px;
        }
}

h5{
    font-size: 16px;
    font-weight: 700;
}
p{
    font-size: 14px;
    font-weight: 300;
}
}