@import '../../../assets/scss/variables.scss';

.propertyTabs {
    background-color: var(--color-white);

    .tabsButton {
        display: flex;
        gap: 10px;
        align-items: center;

        span {
            border-radius: 16px;
            border: 1px solid var(--color-border);
            background: var(--color-active);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px 8px;
            align-items: center;
            @include round-image(24px);
            font-size: 12px;
            font-weight: 500;
            color: var(--color-button);
        }
    }

    :global {
        .MuiTabs-indicator {
            background: $gradient8;
        }

        .MuiTabs-root {
            position: sticky;
            background: var(--color-white);
            border-top: 1px solid var(--color-border);
            border-bottom: 1px solid var(--color-border);
            top: 95px;
            z-index: 8;
            padding-top: 16px;
            width: 100%;
            display: flex;

            @include max-screen($tablet) {
                position: static;
            }

            .MuiTabs-scroller {
                max-width: 1920px;
                margin: 0 auto;
                padding: 9px 60px;
                width: 100%;

                @include max-screen($tablet) {
                    padding: 5px 0;
                }
            }

            .MuiTabs-flexContainer {
                width: 100%;
                justify-content: space-around;
                gap: 12px;

                .MuiButtonBase-root {
                    font-weight: 600;
                    font-size: 14px;
                    min-height: 30px;
                    font-family: $font-family-base;
                    color: var(--color-gray);
                    letter-spacing: 0;
                    text-transform: capitalize;
                    padding: 0 30px;
                    white-space: nowrap;

                    @include max-screen($desktop) {
                        width: auto;
                    }

                    @include max-screen($tablet) {
                        width: auto;
                    }

                    &.Mui-selected {
                        color: var(--color-text);

                        span {
                            span {
                                color: var(--color-blue);
                                background-color: var(--color-blueLight);
                            }
                        }
                    }

                    .MuiTouchRipple-root {
                        display: none;
                    }

                }
            }
        }
    }

    &__content {
        padding-top: 16px;
    }

    &.propertyTabsTop {
       
        :global {
            .MuiTabs-flexContainer {
                justify-content: space-between;
            }
            .MuiButtonBase-root {
                width: 25%;
                &.Mui-disabled{
                    pointer-events: all !important;
                }
            }

            .MuiTabScrollButton-root.MuiTabScrollButton-horizontal {
                width: auto !important;
            }
        }

    }

    &__customLabel {
        font-weight: 600;
        font-size: 14px;
        font-family: $font-family-base;
        color: var(--color-gray);
        display: flex;
        align-items: center;
        gap: 8px;
        span{
            @include round-image(24px);
            background: linear-gradient($color-active, $color-active) padding-box, $gradient8 border-box;
        border: 1px solid transparent;
        color: var(--color-button);
        font-size: 12px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        }
    }
}