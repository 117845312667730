.authStyle {
    &__logo {
        position: relative;
        z-index: 2;
        margin-bottom: 30px;

        &.logo-left {
            margin-bottom: 16px;
        }

        &::after {
            content: "";
            @include background-image(authBg, svg, center center, no-repeat, cover);
            position: absolute;
            width: 100%;
            height: 150px;
            z-index: -1;
            pointer-events: none;

        }

        .left-align & {
            &::after {
                left: -50%;
            }
        }
    }

    &__Icon {
        position: relative;
        z-index: 2;
        margin-bottom: 25px;

        &:after {
            content: "";
            @include background-image(circleLineCenter, svg, center center, no-repeat, cover);
            width: 316px;
            height: 216px;
            display: block;
            position: absolute;
            top: -23px;
            left: 0;
            z-index: 0;
            right: 0;
            margin: 0 auto;
            pointer-events: none;
        }

        .flag {
            border-radius: 28px;
            border: 8px solid var(--color-blueLight);
            background: var(--color-blueBorder);
            display: flex;
            width: 48px;
            height: 48px;
            justify-content: center;
            align-items: center;
            margin: 0 auto 16px;
            color: var(--color-blue);
        }
    }

    &__header {
        text-align: center;
        margin-bottom: 20px;
        position: relative;
        z-index: 2;

        .left-align & {
            text-align: left;
        }

        h2 {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text);


        }

        p {
            font-size: 14px;
            color: var(--color-textLight);
        }

        &.title-xl {
            h2 {
                font-size: 30px;
            }

            p {
                font-size: 16px;
            }
        }
    }

    &__links {
        margin-top: 20px;
            .MuiFormControlLabel-root{
                align-items: flex-start;
                margin: 0;
                .MuiCheckbox-root{
                    padding-left: 0;
                    margin-top: 3px;
                }
            }
        span {
            &[role=link] {
                font-size: 14px;
                color: var(--color-blue);
                font-weight: 600;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    &__footer {
        gap: 12px;
        margin-top: 32px;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        z-index: 2;

        .buttonHalf {
            width: calc(50% - 6px);
        }
    }

    &__social {
        gap: 20px;
        color: var(--color-grayLight);
        margin-bottom: 23px;
        position: relative;
        z-index: 2;

        a {
            color: var(--color-grayLight);
        }
    }
&__footerInfo{
    margin-top: 15px;
    p{
        font-size: 14px;
    color: var(--color-textLight);
  
        a {
            font-size: 14px;
            color: var(--color-primary);
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    
    }
}
    &__copy {
        border-radius: 8px;
        position: relative;
        z-index: 2;
        border: 1px solid var(--color-borderDark);
        background: var(--color-white);
        box-shadow:  $shadow;
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;

        input {
            width: calc(100% - 92px);
            border: 0;
            height: 38px;
            padding: 10px 14px;
            outline: 0;
            font-size: 16px;
            font-weight: 400;
            border-right: 1px solid var(--color-borderDark);
        }

        span {
            font-size: 16px;
            font-weight: 400;
            color: var(--color-button);
            padding: 8px 14px;
            justify-content: center;
            align-items: center;
            display: inline-flex;
            gap: 4px;
            width: 92px;

            &:hover {
                background-color: var(--color-bgLight);
            }
        }
    }

    .checkboxDesc {
        font-size: 14px;
        padding-left: 3px;
        color: var(--color-textLight);
        a{
            color: var(--color-primary);
            text-decoration: none;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}
.verifyEmailHeader{
    background-color: var(--color-white);
    padding: 15px 30px;
    box-shadow: $shadow;
    height: 62px;
    @include max-screen($mobile) {
        padding: 15px;
    }
}
.verifyEmail{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 62px);
    padding: 0 15px;
    &__info{
        background-color: var(--color-white);
        width: 100%;
        max-width: 400px;
        box-shadow: $shadow2;
        border-radius: 10px;
        padding: 15px;
        text-align: center;
        figure{
            border-radius: 28px;
            border: 8px solid var(--color-greenBg);
            background: var(--color-greenLight);
            display: flex;
            width: 48px;
            height: 48px;
            justify-content: center;
            align-items: center;
            margin: 0 auto 5px;
            color: var(--color-greenDark);
            position: relative;
            z-index: 1;
            &.notConfirmed{
                color: var(--color-error);
                border: 8px solid var(--color-errorBg);
                background: var(--color-errorBg);
            }
        }
        h2{
            font-size: 22px;
            margin-bottom: 5px;
        }
        p{
            text-align: center;
        }
    }
    .confirmButton{
        margin: 15px auto;
    }
    &.newPassword{
        min-height: 100vh;
        .verifyEmail__info{
            overflow: hidden;
            padding: 24px;
            .inputGroup{
                text-align: left;
            }
        }
        .footerInfo{
            font-size: 14px;
            margin-top: 15px;
            color: var(--color-textLight);
            span{
                color: var(--color-primary);
                font-weight: 500;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
}
.otpDialog{
    overflow: hidden;
    .closeDialog {
        top: 15px !important;
    }
    .authStyle__Icon{
&::after{
    width: 266px;
    height: 166px;
    top: -12px;
}
    }
}
.restLink{
    margin: 25px 0 0;
}
.notConfirm{
    .verifyEmail__info{
        padding : 0;
        box-shadow: 0 0 0;
    }
    .confirmButton{
        margin-top: 15px;
    }
}