@import '../../assets/scss/variables.scss';

.propertySearchRight {
    padding: 0 15px;
    width: 850px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: calc(100vh - 75px);
    overflow-y: auto;
    @include max-screen($desktop-m) {
        width: 700px;
    }
    @include max-screen($tablet) {
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    &__filters {
        border-radius: 8px;
        border: 1px solid var(--color-border);
        background: var(--color-white);
        padding: 10px;
        margin-top: 6px;
    }
&__saveFiltersDropdown{
    width: 100%;
    gap: 8px;
}
    &__saveFilters {
        gap: 8px;
        width: 100%;
        @include max-screen($mobile) {
            flex-direction: column;
            margin-top: 15px;
          }
        :global {
            .inputSelect {
                margin-bottom: 0;
                width: 100%;
                .MuiInputBase-root{
                    height: 40px;
                    .MuiInput-input{
                        padding: 4px 4px 4px 0px;
                        font-weight: 600;
                        font-size: 14px;
                        &::placeholder{
                            color: var(--color-button);
                        }
                       
                    }
                }
            }

            .button {
                padding: 9px 25px;
            }

        }
    }

    &__filtersHead {
        gap: 15px;

        @include max-screen($mobile) {
            flex-wrap: wrap;
            gap: 0;
        }

        p {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 1.2;
            min-width: 143px;

            @include max-screen($mobile) {
                width: 100%;
                order: 0;
            }

            strong {
                font-size: 32px;
                font-weight: bold;
            }

            span {
                font-weight: normal;
            }
        }

    }

    &__createList {
        @include max-screen($mobile) {
            order: 2;
            width: 100%;
        }
    }

    &__AllFiltersBtn {
        width: 124px;

        @include max-screen($mobile) {
          width: 100%;
        }

        :global {
            .iconSmall {
                .button__icon {
                    width: 12px;
                    height: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 4px;
                }

                .rotateIcon {
                    .button__icon {
                        transform: rotate(180deg);
                    }
                }
            }

        }
    }

    &__filterAdvance {
        border-top: 1px solid var(--color-borderDark);
        margin-top: 12px;
        :global{
            .filterMenu__footer{
                position: sticky;
                bottom: 0;
            }
            .filterMenu__info{
                max-height: 100%;
                padding: 10px;
            }
        }
    }

    &__loader {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: relative;
    }
    &__filter{
        width: 100%;
      
        h5{
            font-size: 14px;
            font-weight: 600;
            color: var(--color-button);
            margin-bottom: 10px;
            @include max-screen($mobile) {
              text-align: center;
              }
        }
        ul{
            position: relative;
             padding: 0 8px 10px;
             margin: 0 0 10px 0;
             border-bottom: 1px solid var(--color-borderDark);
             @include max-screen($mobile) {
                margin: 0 auto 10px;
                position: relative;
                border-left: 0;
                padding: 5px 10px;
                width: fit-content;
              }
            li{
                span{
                    font-size: 14px;
                    font-weight: 600;
                    color: var(--color-grayLight);
                    padding: 3px 16px;
                    border-radius: 55px;
                    height: 28px;
                    display: block;
                    white-space: nowrap;
                    @include max-screen($mobile) {
                       min-width: 100px;
                       display: flex;
                      justify-content: center;
                     }
                    &.active{
                        background-color: var(--color-blue-pills-bg);
                        color: var(--color-blue-pills-text);
                        box-shadow: $shadow;
                    }
                }
            }
        }
    }

}

.propertyList {
    border-radius: 8px;
    overflow: hidden;

    &__inner {
        overflow-y: auto;
        height: 100%;
        border-radius: 8px;
        border: 1px solid var(--color-border);
        background: var(--color-white);
        min-height: calc(100vh - 280px);

        @include max-screen($tablet) {
            height: 550px;
        }

        :global {
            .infinite-scroll-component {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }

    &__loader {
        background-color: rgba($color-black, 0.2);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
    }

    &__box {

        width: 50%;

        @include max-screen($mobile) {
            width: 100%;
        }

        &:hover,
        &.boxActive {
            figcaption {
                background: rgba(147, 147, 147, 0.14);
            }

        }
    }
   
}