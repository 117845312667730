@import '../../assets/scss/variables.scss';

.contactInfo {
    .headerIcon {
        position: relative;
        z-index: 2;
        margin-bottom: 25px;
        &.spaceBottom{
            margin-bottom: 10px;
        }
        &:before,
        &:after {
            content: "";
            width: 208px;
            height: 208px;
            display: block;
            position: absolute;
            top: -81px;
            left: -86px;
            z-index: 1;
            pointer-events: none;
            background-color: var(--color-white);

        }

        &:after {
            @include background-image(animated-circle, gif, center center, no-repeat, 200px);
            width: 216px;
            height: 216px;
            mix-blend-mode: difference;
            opacity: 5%;

        }

        svg {
            position: relative;
            z-index: 2;
            width: 48px;
            height: 48px;
            padding: 10px;
        }

        .flag {
            border-radius: 10px;
            border: 1px solid var(--color-border);
            background: var(--color-white);
            box-shadow: $shadow;
            display: flex;
            width: 48px;
            height: 48px;
            justify-content: center;
            align-items: center;

        }
        .flagBorder{
            position: relative;
            margin-top: -15px;
           
            &::after{
                content: "";
                position: absolute;
                z-index: 1;
                width: 100%;
                height: 100%;
                
            }
            &::before{
                content: "";
                position: absolute;
                z-index: 2;
                width: 100%;
                height: 100%;
                background-color: var(--color-white);
                border-radius: 10px;
                border: 1px solid var(--color-border);
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }
        }

 
    }
    &__header {
        margin-bottom: 20px;
        position: relative;
        z-index: 3;

        h3 {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text);
            margin-bottom: 4px;

            span {
                font-size: 12px;
                font-weight: 400;
            }
        }

        p {
            font-size: 14px;
            font-weight: 400;
            color: var(--color-textLight);

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }
&__form{
    margin-top: 15px;
}
  &__tab{
    margin-top: 20px;
    p{
        font-size: 14px;
        font-weight: 500;
        color: var(--color-button);
    }
    ul{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;
        border: 1px solid var(--color-borderDark);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        margin-top: 10px;
        overflow: hidden;
        li{
            width: 50%;
            span{
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: center;
                gap: 8px;
                padding: 8px 16px;
                font-size: 16px;
                font-weight: 600;
                color: var(--color-button);
                svg{
                    width: 20px;
                    height: 20px;
                    color: var(--color-gray);
                }
                &.active{
                    background-color:  var(--color-active);
                }
                &.disabled{
                    pointer-events: none;
                    opacity: 0.5;
                }
            }
            &:not(:last-child){
                border-right: 1px solid var(--color-borderDark);
            }
        }
    }
  }
}
