@import '../../assets/scss/variables.scss';

.navRight {
    gap: 16px;
    @include max-screen($desktop) {
        gap: 8px;
     }
    li {
        @include max-screen($tablet) {
           width: 100%;
           text-align: center;
        }
        a{
            color: var(--color-black);
            font-size: 16px;
            text-decoration: none;
            font-family: $font-poppins;
          
            &:hover{
                color: var(--color-button);
            }
        }
        .setting {
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--color-white);
            border-radius: 6px;
            color: var(--color-gray);
            &:hover,
            &.active {
                color: var(--color-primary);
                background-color: var(--color-white);
            }

        }

        .profile {
            // img {
            //     @include round-image(40px);
            // }
            font-size: 14px;
            font-weight: 600;
            gap: 6px;
            display: flex;
            align-items: center;

        }
        button{
            @include max-screen($tablet) {
                width: 100%;
             }
        }
  &.hideMobile{
    @include max-screen($tablet) {
        display: none;
     }
  }
  &.firstNav{
    margin-right: 8px;
    @include max-screen($tablet) {
        margin-right: 0;
     }
  }
    }
    &.navRightNotLogIn{
        gap: 32px;
        @include max-screen($tablet) {
            gap: 16px;
         }
    }
  
}