@import '../../assets/scss/variables.scss';

.subscribeModal{
width: 100%;
&__list{
    ul{
        margin-top: 0;
        li{
            width: 100%;
        }
        header{
            margin-bottom: 24px;
        }
    }
}
.textSpace{
    margin-bottom: 20px;
    color: var(--color-gray);
}
:global{
    .priceCardHeader{
        padding-top: 50px !important;
        padding-bottom: 20px !important;
    }
    .priceCard{
        border: 0;
        box-shadow: 0 0 0;
    }
}
}