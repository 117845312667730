@import '../../assets/scss/variables.scss';
.loader {
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: var(--color-white);
    span {
        width: 50px;
        height: 50px;
        display: flex;
        border-radius: 50%;
        margin-bottom: 3px;
        align-items: center;
        justify-content: center;
        border: 3px dotted $color-green;
        border-style: solid solid dotted dotted;
        animation: spin 2s linear infinite;
        &::after {
            content: '';
            inset: 7px;
            border-radius: 50%;
            position: absolute;
            border: 3px dotted $color-primary;
            border-style: solid solid dotted dotted;
            animation: spinInner 1s linear infinite;
        }
    }
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes spinInner {
        0% {
            transform: rotate(360deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
}