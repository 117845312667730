@import '../../assets/scss/variables.scss';

.profileMenu {
    &__item {
        font-size: 14px;
        font-weight: 600;
        gap: 6px;
        display: flex;
        align-items: center;

        @include max-screen($tablet) {
            display: none;
        }
    }
    &__user{
        line-height: 1.2;
    }
    &__userName{
        text-overflow: ellipsis;   
        overflow:hidden;           
        white-space:nowrap;           
        max-width: 100px;
        font-size: 12px;
        font-weight: 400;
        display: inline-block;
     color: var(--color-textLight);
    }
    &__userAccount{
  
     gap: 5px;
     strong{
        font-size: 14px;
        color: var(--color-textLight);
        font-weight: 700; 
     }
    }

    ul {
        display: none;

        @include max-screen($tablet) {
            display: block;
        }

        li {
            a, .profileMenu {
                font-size: 16px;
                font-weight: 600;
                padding: 8px 12px;
                display: flex;
                gap: 12px;
                background-color: var(--color-white);
                border-radius: 6px;
                align-items: center;
                color: var(--color-links);
                text-decoration: none;
                @include max-screen($tablet) {
                color: var(--color-textLight);
                }
                .navIcon{
                    display: inline-flex;
                    color: var(--color-gray);
                    svg{
                        @include max-screen($tablet) {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
                &:hover,
                &.active {
                    background-color: var(--color-active);
                }
            }
            .logout{
                margin-top: 24px;
                padding-top: 24px;
                padding-left: 8px;
                border-top: 1px solid var(--color-border);
                font-weight: 500;
                cursor: pointer;

                
                p{
                    color: var(--color-textLight);
                    font-size: 14px;
                    strong{
                        display: block;
                        color: var(--color-button);
                    }
                }
                figure{
                    width: 36px;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--color-gray);
                }
        }
        }
    }

}
.menuLink{
    gap: 8px;
                    display: flex;
                    font-size: 14px;
                    font-weight: 500;
                    font-family: $font-family-base;
                    color: var(--color-button);
                    background-color: var(--color-white) !important;
                    padding: 9px 16px;
                    width: 100%;
                    align-items: center;
                    &:hover {
                        background-color: var(--color-active) !important;
                    }
}
:global {
    .profileMenuList {
        .MuiPaper-root {
            border-radius: 8px;
            border: 1px solid var(--color-border);
            background: var(--color-white);
            box-shadow: $shadow5;
            min-width: 161px;
            margin-top: 15px;

            .MuiList-root {
                padding: 4px 0;

                .MuiMenuItem-root {
                    gap: 8px;
                    display: flex;
                    font-size: 14px;
                    font-weight: 500;
                    font-family: $font-family-base;
                    color: var(--color-button);
                    background-color: var(--color-white) !important;
                    padding: 0;

                    &:hover {
                        background-color: var(--color-active) !important;
                    }

                   &.logoutMenu{
                        border-top: 1px solid var(--color-border);
                    }

                    svg {
                        color: var(--color-gray);
                        width: 16px;
                        height: 16px;
                    }

                    .MuiTouchRipple-root {
                        display: none;
                    }
                    a{
                        text-decoration: none;
                        gap: 8px;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 500;
                    font-family: $font-family-base;
                    color: var(--color-button);
                    padding: 9px 16px;
                    width: 100%;
                    }
                }

            }
        }
    }
}