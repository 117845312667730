@import '../../../../assets/scss/variables.scss';

.call {
    background-color: var(--color-active);
    padding: 30px 30px 0;
    @include max-screen($desktop) {
        padding:  0;
     }
    &__attemptList {
        padding-bottom: 50px;

        h3 {
            font-size: 18px;
            color: var(--color-text);

        }
    }
&__main{
    width: 100%;
}
    &__attemptPhone {
        gap: 30px;
        width: 100%;
        &:not(:last-child){
            margin-bottom: 12px;
        }
        @include max-screen($tablet) {
            justify-content: space-between;
            gap: 10px;
         }
        @include max-screen($mobile) {
           flex-wrap: wrap;
        }
        figure {
            gap: 10px;

            label {
                white-space: nowrap;
                font-weight: 500;
                font-size: 14px;
                color: var(--color-text);
                @include min-screen($tablet) {
                    min-width: 74px;
                }
            }
        }

        figcaption {
            a {
                white-space: nowrap;
                color: var(--color-black);
                text-decoration: none;
                font-size: 14px;
               margin-right: 50px;
               min-width: 110px;
                @include max-screen($tablet) {
                    margin-right: 10px;
                }
            }
        }
    }

    &__attemptPhoneMain{
        gap: 10px;
    }
    &__copy {
        color: var(--color-button);
    }
&__headerRight{
    gap: 10px;
    :global { .inputSelect{
            margin: 0;
            min-width:200px;
            .MuiInput-root .MuiInput-input{
                color: var(--color-text);
                font-weight: 500;
            }
            .MuiInputBase-root{
                height: 40px;
            }
        }
    }
    
}
    &__status {
        width: 100%;
        @include min-screen($mobile) {
            max-width: 195px;
        }
        :global {
            .inputSelect .MuiInputBase-root {
                border-color: var(--color-gray);
                height: 40px;

                .MuiInputBase-input {
                    font-weight: 600;
                    font-size: 14px;
                    color: var(--color-text);
                    text-align: center;
                }
            }
        }
        &.disabled{
          :global  .inputSelect .MuiInputBase-root {
                border-color: var(--color-borderDark);
            }
        }
    }

    &__info {
        width: 530px;

        @include max-screen($tablet) {
            width: 100%;
        }
    }

    &__note {
        width: calc(100% - 530px);
        padding-left: 50px;

        @include max-screen($tablet) {
            width: 100%;
            padding-left: 0px;
            padding-top: 15px;
        }
    }

    &__header {
        margin-bottom: 15px;
        @include max-screen($mobile) {
           flex-direction: column;
          align-items: flex-start;
          gap: 10px;
         }
    }
}