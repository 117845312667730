@import '../../assets/scss/variables.scss';
.toggleMenu{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;

    &__inner{
        background-color: var(--color-white);
        width: 295px;
        height: 100%;
        overflow-y: auto;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
.toggleMenuWhite{
    color: var(--color-black);
}