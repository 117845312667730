@import '../../assets/scss/variables.scss';

.createCampaign {
    .headerIcon {
        position: relative;
        z-index: 2;
        margin-bottom: 25px;
        &.spaceBottom{
            margin-bottom: 10px;
        }
        &:before,
        &:after {
            content: "";
            width: 208px;
            height: 208px;
            display: block;
            position: absolute;
            top: -81px;
            left: -86px;
            z-index: 1;
            pointer-events: none;
            background-color: var(--color-white);

        }

        &:after {
            @include background-image(animated-circle, gif, center center, no-repeat, 200px);
            width: 216px;
            height: 216px;
            mix-blend-mode: difference;
            opacity: 5%;

        }

        svg {
            position: relative;
            z-index: 2;
            width: 48px;
            height: 48px;
            padding: 10px;
        }

        .flag {
            border-radius: 10px;
            border: 1px solid var(--color-border);
            background: var(--color-white);
            box-shadow: $shadow;
            display: flex;
            width: 48px;
            height: 48px;
            justify-content: center;
            align-items: center;

        }
        .flagBorder{
            position: relative;
            margin-top: -15px;
           
            &::after{
                content: "";
                position: absolute;
                z-index: 1;
                width: 100%;
                height: 100%;
                
            }
            &::before{
                content: "";
                position: absolute;
                z-index: 2;
                width: 100%;
                height: 100%;
                background-color: var(--color-white);
                border-radius: 10px;
                border: 1px solid var(--color-border);
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }
        }

        &.orange {

            span {
                position: relative;
                z-index: 2;
                border: 8px solid var(--color-warningBg);
                background: var(--color-warning);
                color: var(--color-warningText);
                @include round-image(48px);
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    width: 24px;
                    height: 24px;
                    padding: 0;
                }
            }
        }
    }
&__total{
    color: var(--color-text);
   strong{
    font-weight: 500;
   }
   p{
    strong{
        font-weight: 600;
    }
   }
}
&__totalText{
    p{
        small{
            font-size: 14px;
        }
    }
}
&__bottomText{
    margin: 15px 0 32px;
    p{
        font-size: 12px;
        color: var(--color-button);
    }
}
    &__header {
        margin-bottom: 20px;
        position: relative;
        z-index: 3;

        h3 {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text);
            margin-bottom: 4px;

            span {
                font-size: 12px;
                font-weight: 400;
            }
        }

        p {
            font-size: 14px;
            font-weight: 400;
            color: var(--color-textLight);

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }
    &__skipTrace{
        margin-top: 20px;
        border-top: 1px solid var(--color-border);
        padding-top: 10px;
    }
    &__skipTraceRow{
        label{
            font-size: 14px;
            font-weight: 500;
            color: var(--color-button);
        }
        h5{
            font-size: 20px;
            font-weight: 700;
            color: var(--color-button);
        }
    }
    .credits {
        margin-bottom: 16px;
        font-size: 16px;
        color: var(--color-text);
        span{
            text-decoration: underline;
            color: var(--color-primary);
            font-weight: 500;
        }
    }

    .creditsUpgrade {
        padding: 12px 0;
        border-bottom: 1px solid var(--color-border);
        background: var(--color-white);
        margin-bottom: 24px;
        gap: 10px;

        @include max-screen($smobile) {
            flex-wrap: wrap;
            justify-content: center;
        }

        .info {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
        }

        p {
            font-size: 14px;
            color: var(--color-textLight);


            strong {
                color: var(--color-button);
            }


        }
    }

    .switch {
        gap: 8px;
        margin-bottom: 16px;

        h5 {
            font-size: 14px;
            font-weight: 500;
            color: var(--color-button);
        }

        p {
            font-size: 14px;
            font-weight: 400;
            color: var(--color-textLight);
        }
    }

    &__action {
        margin-top: 32px;
    }
    &__actionNext{
        @include max-screen($mobile) {
            margin-top: 32px;
        }
    }

    .step2Button {
        @include max-screen($mobile) {
            margin-top: 15px;
        }
    }

    h4 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
        color: var(--color-button);
    }

    .step2 {
        :global {
            .inputSelect {
                margin-bottom: 10px;
            }
        }
    }

    .mainCol {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        &__half {
            width: 50%;
            padding: 0 10px;

            @include max-screen($mobile) {
                width: 100%;
            }
        }

    }

    .campaignCreated {
        text-align: center;
        position: relative;

        &:after {
            content: "";
            @include background-image(confetti, gif, center center, no-repeat, cover);
            position: absolute;
            width: 160px;
            height: 160px;
            top: -50px;
            left: 0;
            margin: 0 auto;
            right: 0;
            z-index: 2;
        }


        figure {
            border-radius: 28px;
            border: 8px solid var(--color-greenBg);
            background: var(--color-greenLight);
            display: flex;
            width: 48px;
            height: 48px;
            justify-content: center;
            align-items: center;
            margin: 0 auto 16px;
            color: var(--color-greenDark);
            position: relative;
            z-index: 1;

        }
    }

    &__upBuy {
        gap: 10px;

        .list {
            width: calc(50% - 10px);

            @include max-screen($mobile) {
                width: 100%;
            }

            p {
                font-size: 14px;
                font-weight: 500;
                color: var(--color-button);
                display: block;
                margin-bottom: 6px;
            }
        }

        .uploadBuy {
            width: 50%;
            min-height: 95px;

            @include max-screen($mobile) {
                width: 100%;
                min-height: 0;
            }

            span {
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 8px 16px 8px 14px;
                background: var(--color-white);
                box-shadow: $shadow;

                &:not(:last-child) {
                    border-right: 1px solid var(--color-borderDark);
                }

                &:hover {
                    background-color: var(--color-bgLight);
                }
            }

            &__inner {
                border-radius: 8px;
                border: 1px solid var(--color-borderDark);
                overflow: hidden;
                min-height: 42px;
            }
        }
    }

    .legal {
        &__header {
            h3 {
                font-size: 18px;
                font-weight: 600;
                color: var(--color-text);
                text-align: center;
                padding-bottom: 5px;
            }
        }

        &__info {
            max-height: 230px;
            overflow-y: auto;

            @include max-screen($mobile) {
                max-height: calc(100vh - 230px);
            }

            &::-webkit-scrollbar {
                width: 0;
            }

            p {
                font-size: 14px;
                font-weight: 400;
                color: var(--color-textLight);

                &:not(:last-child) {
                    margin-bottom: 15px;
                }
            }
        }

        &__footer {
            padding-top: 32px;

            .buttons {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -10px;

                @include max-screen($mobile) {
                    gap: 15px;
                }

                &__first {
                    width: calc(100% - 240px);
                    padding: 0 10px;

                    @include max-screen($mobile) {
                        width: 100%;
                    }
                }

                &__last {
                    width: 240px;
                    padding: 0 10px;

                    @include max-screen($mobile) {
                        width: 100%;
                    }
                }

                :global {
                    .button {
                        font-size: 16px;
                    }
                }
            }
        }

        &__infoList{
            font-size: 14px;
            color: var(--color-textLight);
            margin-bottom: 15px;
            li{
                &:not(:last-child){
                    margin-bottom: 15px;
                }
            }
        }
    }

    .termsLink {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.expect {
    margin-bottom: 32px;

    .headerIcon {
        width: 80px;
        color: var(--color-greenDark);

        span {
            position: relative;
            display: block;

            svg {
                z-index: 4;
            }

            &:before {
                content: "";
                @include round-image(50px);
                background-color: var(--color-greenLight);
                position: absolute;
                left: -10px;
                top: -10px;
                z-index: 3;
                border: 10px solid var(--color-greenBg)
            }
        }
    }

    .expectContent {
        width: calc(100% - 80px);
        position: relative;
        z-index: 2;

        h3 {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text);
        }

        p {
            font-size: 14px;
            font-weight: 400;
            color: var(--color-text);
            margin-bottom: 15px;
        }

        ul {
            padding-left: 20px;
            list-style: disc;

            li {
                font-size: 14px;
                font-weight: 400;
                color: var(--color-text);
            }
        }
    }
}

.creditsInfo {
    gap: 10px;
margin-bottom: 20px;
    &__circle {
        @include round-image(48px);
        border: 8px solid var(--color-greenBg);
        background: var(--color-greenLight);
        align-items: center;
        justify-content: center;
        display: flex;
        color: var(--color-green);

        svg {
            width: 24px;
            height: 24px;
        }

        &.orange {
            border: 8px solid var(--color-warningBg);
            background: var(--color-warning);
            color: var(--color-warningText);
        }
    }

    &__info {
        p {
            font-size: 14px;
            color: var(--color-textLight);
        }
    }
}