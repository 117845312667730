@import '../../assets/scss/variables.scss';

.navLeft {
    gap: 4px;
    @include max-screen($tablet) {
        margin-top: 20px;
    }
    li {
        @include max-screen($tablet) {
            width: 100%;
        }
        span, a {
            font-size: 14px;
            font-weight: 600;
            padding: 8px 12px;
            background-color: var(--color-white);
            border-radius: 6px;
            display: inline-flex;
            align-items: center;
            gap: 10px;
            color: var( --color-textLight);
            text-decoration: none;
            position: relative;
        
            @include max-screen($desktop) {
                padding: 8px 5px;
                display: flex;
                gap:5px;
            }
            @include max-screen($tablet) {
                padding: 8px 12px;
                display: flex;
                gap:12px;
                font-size: 16px;
            }
           
          
           
            &.navIcon{
                padding: 0;
                @include max-screen($tablet) {
                 display: inline-flex;
                color: var(--color-button);
                }
                svg{
                    width: 20px;
                    height: 20px;
                    @include max-screen($tablet) {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
        a{
            &::after{
                content: "";
                height: 2px;
                background: $gradient8;
                width: 0;
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                bottom: 0;
                opacity: 0;
                transition: all 0.5s;
            }
            &:hover,
            &.active {
                @include max-screen($tablet) {
                    background-color: var(--color-active);
                   }
                
               &::after{
                opacity: 1;
                width: 100%;
                @include max-screen($tablet) {
                 opacity: 0;
                }
               }
            }
        }
        &.hideDesktop{
            display: none;
            @include max-screen($tablet) {
                display: block;
               }
        }
    }
    &.navWhite{
        span, a {
            background-color: transparent;
            color: var(--color-white);

            &:hover,
            &.active {
                background-color: transparent;
            }
        }
    }
}