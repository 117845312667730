* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html{
    scroll-behavior: smooth;
}
body {
    color: var(--color-text);
    font-size: $font-size-base;
    font-family: $font-family-base;
    line-height: $line-height-base;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: var(--color-active);

}

ul,
ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

[role=link] {
    cursor: pointer;
}
[role=default] {
    cursor: default;
}
[role=disabled] {
    cursor: not-allowed;
    pointer-events: none;
}

.wrapper {
    padding: 0 60px 45px;
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
    &.bottom-zero{
        padding-bottom: 0;
    }

    @include max-screen($desktop-large) {
        padding: 0 35px 35px;
    }
    @include max-screen($tablet) {
        padding: 0 15px 35px;
    }
    @include max-screen($mobile) {
        padding: 0 15px 15px;
    }
}

.wrapper-md {
    padding: 0 32px;
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;

    @include max-screen($desktop) {
        padding: 0 15px;
    }
}

::-webkit-scrollbar {
    width: 5px;
    height: 4px;
}


::-webkit-scrollbar-thumb {
    background: var(--color-borderDark);
    border-radius: 4px;
}

.logo-main {
    width: 192px;
    height: 35px;
}

.logo-icon {
    width: 46px;
    height: 30px;
}
.check-list{
    width: 100%;
    ul{
        margin-top: 24px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 16px;
        li{
            font-size: 16px;
            color: var(--color-textLight);
            position: relative;
            padding-left: 32px;
            &::before{
                content: "";
                @include background-image(check-icon, svg, center center, no-repeat, cover);
                width: 24px;
                height: 24px;
                position: absolute;
                top: 0;
                left: 0;
            }
            &:not(:last-child){
                margin-bottom: 16px;
            }
        }
    }
}
.page-layout {
    min-height: calc(100dvh - 155px);
    @include max-screen($mobile){
        min-height: calc(100dvh - 160px);
    }
}

.disableScroll {
    overflow: hidden;
}
.font-secondary{
    font-family: $font-poppins !important;
}
.font-aeonic{
    font-family: $font-aeonic !important;
}

.font-normal{
    font-weight: 400 !important;
}
.modal-open {
    overflow: hidden;
  }