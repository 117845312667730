@import '../../../../assets/scss/variables.scss';
.propertyDetailsTab {
    background-color: var(--color-active);
    gap: 15px;
    // margin-bottom: 10px;
    // margin-top: -16px;
    box-shadow: $shadow6;
    padding: 30px;
    border-bottom: 1px solid var(--color-border);
    margin-bottom: 32px;
    @include max-screen($tablet) {
        padding: 15px;

    }
    .propertyBox {
        align-items: flex-start;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        gap: 5px;
     

      

        @include max-screen($mobile) {
            width: calc(50% - 15px);

        }

        h3 {
            font-size: 18px;
            color: var(--color-text);
            display: flex;
            align-items: center;
            gap: 4px;
            margin-top: 15px;

            span {
                border-radius: 6px;
                border: 1px solid var(--color-borderDark);
                background: var(--color-white);
                box-shadow: $shadow;
                display: inline-flex;
                padding: 5px;
                color: var(--color-textLight);
                font-size: 12px;
                line-height: 1;
            }

            small {
                font-size: 12px;
                font-weight: 400;
            }
        }

        p {
            font-size: 14px;
            color: var(--color-textLight);
        }

        .progress {
            width: 100%;
            max-width: 224px;
            :global{
                .MuiLinearProgress-bar{
                    background: $gradient8;
                }
            }
        }
        &__edit{
            display: flex;
            align-items: center;
            gap: 6px;
            span{
                svg{
                    width: 12px;
                    height: 12px;
                    color: var(--color-black);
                }
            }
        }
    }
}
.row {
    gap: 15px;

    &__col {
        width: calc(16.66% - 13px);
        @include max-screen($tablet) {
            width: calc(33.3% - 12px);
        }
        @include max-screen($mobile) {
            width: calc(50% - 12px);
        }
        @include max-screen($smobile) {
            width: 100%;
        }
    }
}