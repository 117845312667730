@import '../../assets/scss/variables.scss';

.leads {
    &.leadsActive{
        max-width: calc(100% - 490px);
        @include max-screen($desktop-large) {
            max-width: calc(100% - 520px);
        }
        @include max-screen($tablet) {
            max-width: 100%;
        }
    }
    &__header{
        gap: 5px;
        .search{
            width: calc(100% - 307px);
            @include max-screen($mobile) {
               width: 100%;
             }
        }
        .filters{
            gap: 5px; 
            width: 302px; 
            @include max-screen($mobile) {
                width: 100%;
              }
              :global{
                .filter-icon{
                    min-width: 108px;
                   .button__text{
                    & + .button__icon{
                        position: absolute;
                        left: 32px;
                        top: 5px;
                        svg{
                            width: 15px;
                            height: 15px;
                        }
                    }
                   }
                }
              }
        }
    }
    &__table {
        margin-top: 24px;
    }
}