@import '../../assets/scss/variables.scss';

.vaPlan {
    &__info {
        gap: 64px;
        @include max-screen($desktop-xlarge) {
            gap: 30px;
        }
        @include max-screen($desktop-large) {
            gap: 20px;
        }
        @include max-screen($tablet) {
            gap: 24px;
        }

        .vaPlanInfo {
            width: calc(50% - 32px);
            position: relative;
            z-index: 2;
            @include max-screen($desktop-xlarge) {
                width: calc(50% - 15px);
            }
            @include max-screen($desktop-large) {
                width: calc(50% - 10px);
            }
            @include max-screen($tablet) {
                width: 100%;
            }

            &::after {
                content: "";
                @include background-image(pattern-square, svg, center center, no-repeat, cover);
                position: absolute;
                width: 336px;
                height: 336px;
                z-index: -1;
                pointer-events: none;
                top: -110px;
                left: -110px;
            }

            &__header {
                margin-bottom: 24px;

                h2 {
                    font-size: 36px;
                    font-weight: 600;
                    margin-bottom: 20px;
                    color: var(--color-text);

                    @include max-screen($tablet) {
                        font-size: 26px;
                        margin-bottom: 5px;
                    }
                }

                p {
                    font-size: 18px;
                    color: var(--color-textLight);

                    @include max-screen($tablet) {
                        font-size: 16px;
                    }
                }
            }

            ul {
                li {
                    display: flex;
                    gap: 16px;

                    &:not(:last-child) {
                        margin-bottom: 48px;
                        @include max-screen($desktop-large) {
                            margin-bottom: 8px;
                        }

                      
                    }

                    figure {
                        @include round-image(48px);
                        border-radius: 28px;
                        border: 8px solid var(--color-blueLight);
                        background: var(--color-blueBorder);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: var(--color-primary);

                        svg {
                            width: 24px;
                            height: 24px;
                        }
                    }

                    h3 {
                        font-size: 20px;
                        font-weight: 600;
                        color: var(--color-text);
                        margin: 10px 0 8px;
                    }

                    p {
                        font-size: 16px;
                        color: var(--color-textLight);
                    }
                }
            }
        }

        .vaPlanCard {
            width: 100%;
        }
       
    }
 
}
.vaTerm {
    &__header {
        h3 {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text);
            text-align: center;
            padding-bottom: 5px;
        }
    }

    &__info {
        max-height: 230px;
        overflow-y: auto;

        @include max-screen($mobile) {
            max-height: calc(100vh - 230px);
        }

        &::-webkit-scrollbar {
            width: 0;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            color: var(--color-textLight);

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }

    &__footer {
        padding-top: 32px;

        .buttons {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px;

            @include max-screen($mobile) {
                gap: 15px;
            }

            &__first {
                width: calc(100% - 240px);
                padding: 0 10px;

                @include max-screen($mobile) {
                    width: 100%;
                }
            }

            &__last {
                width: 240px;
                padding: 0 10px;

                @include max-screen($mobile) {
                    width: 100%;
                }
            }

            :global {
                .button {
                    font-size: 16px;
                }
            }
        }
    }

    &__infoList{
        font-size: 14px;
        color: var(--color-textLight);
        margin-bottom: 15px;
        li{
            &:not(:last-child){
                margin-bottom: 15px;
            }
        }
    }
}