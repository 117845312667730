@import '../../assets/scss/variables.scss';

.notificationsMenu {
    gap: 12px;
    figure {
        color: var(--color-links);
        font-size: 16px;
        font-weight: 600;
        gap: 5px;
        @include max-screen($tablet) {
            color: var(--color-textLight);
        }
    }
    .toggleIconBefore{
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.1s;
        &.toggleIcon{
            transform: rotate(180deg);
        }
    }
   
    &__item {
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--color-white);
        border-radius: 6px;
        color: var(--color-gray);
        position: relative;
        

        &:hover,
        &.active {
            background-color: var(--color-active);
        }
        svg{
            color: var(--color-gray); 
        }
        .notificationsNo {
            position: absolute;
            background-color: var(--color-error);
            border-radius: 1000px;
            min-width: 15px;
            min-height: 15px;
            font-size: 10px;
            font-weight: 600;
            color: var(--color-white);
            top: 0px;
            right: 5px;
            padding: 0 3px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }

      
    }


}

.notificationsDesktop {
    @include max-screen($tablet) {
        display: none;
    }
}

.notificationsMobile {
    display: none;

    @include max-screen($tablet) {
        display: block;
    }
    :global{
        .notification{
            opacity: 0;
            transition: all 1s;
            transition-delay: 1s;
            height: 0;
            &.mobileNotificationActive{
                height: auto;
                opacity: 1;
            }
        }
       
    }

}

:global {
    .notificationsMenuList {
        .MuiPaper-root {
            border-radius: 8px;
            border: 1px solid var(--color-border);
            background: var(--color-white);
            box-shadow: $shadow5;
            width: 345px;
            margin-top: 8px;
            margin-left: 20px;

        }
    }

    .notification {
        &__header {
            padding: 12px;

            @include max-screen($tablet) {
                display: none !important;
            }

            h4 {
                font-size: 16px;
                color: var(--color-text);
                font-weight: 600;
            }

            svg {
                width: 20px;
                height: 20px;
                color: var(--color-gray);
            }
        }

        &__list {
            max-height: 200px;
            overflow-y: auto;
            margin-right: 4px;
            @include max-screen($tablet) {
                margin-right: 0;
            }

            ul {
                padding: 12px;
                @include max-screen($tablet) {
                    padding: 0;
                }
                li {
                    padding: 12px 28px 12px 12px;
                    display: block;
                    background-color: var(--color-white) !important;
                    border-radius: 8px;
                    position: relative;
                    background-color: var(--color-active) !important;
                    cursor: default;
                    &:not(:last-child){
                        margin-bottom: 5px;
                    }
                    &:hover {
                        background-color: var(--color-bgLight) !important;
                    }

                    p {
                        font-size: 14px;
                        font-family: $font-family-base;
                        color: var(--color-gray);
                        white-space: normal;

                        &.notificationTime {
                            font-weight: 600;
                        }
                    }

                    .MuiTouchRipple-root {
                        display: none;
                    }

                    &.unread {
                        background-color: var(--color-white) !important;
                        cursor: pointer;
                        &:hover {
                            background-color: var(--color-active) !important;
                        }
                        &::after {
                            content: "";
                            @include round-image(12px);
                            background-color: var(--color-primary);
                            position: absolute;
                            right: 5px;
                            top: 50%;
                            transform: translateY(-50%);

                        }

                        p {
                            color: var(--color-button);

                            &.notificationTime {
                                color: var(--color-primary);
                            }
                        }
                    }
                }
            }

            .noList {
                text-align: center;
                margin: 8px 0 15px 0;
                @include max-screen($tablet) {
                    text-align: left;
                    padding: 0 15px;
                }
                h4 {
                    font-size: 14px;
                    color: var(--color-text);
                    font-weight: 600;
                }

                p {
                    font-size: 12px;
                    color: var(--color-textLight);
                }

            }
        }

    }
}