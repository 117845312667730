@import '../../assets/scss/variables.scss';

.integration {
    &__header {
        padding-bottom: 24px;
        border-bottom: 1px solid var(--color-border);
        margin-bottom: 24px;

        h3 {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text);
            display: inline-flex;
            align-items: center;
            gap: 10px;
            span{
                display: inline-flex;
            }
            svg{width: 14px;
            height: 14px;
            color: var(--color-textLight);}
        }

        p {
            font-size: 14px;
            color: var(--color-textLight);
        }
    }
  
    &__headerOther {
        border-bottom: 0;
        margin-bottom: 0;
        padding-top: 32px;
        margin-top: 24px;
        border-top: 1px solid var(--color-border);
    }

    &__form {
        .input {
            border-bottom: 1px solid var(--color-border);
            padding-bottom: 20px;
            margin-bottom: 20px;

            @include max-screen($stablet) {
                border-bottom: 0;
                padding-bottom: 0;
                margin-bottom: 16px;
            }
            &__payload{
                background-color: var(--color-white);
                border: 1px solid var(--color-borderDark);
                margin: 5px 0 15px;
                padding: 10px 35px 10px 10px;
                font-size: 14px;
                border-radius: 8px;
                box-shadow: $shadow;
                color: var(--color-textLight);
                position: relative;
            }
            &__copy{
                padding: 2px;
                border-radius: 4px;
                background-color: var(--color-primary);
                color: var(--color-white);
                width: 25px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 5px;
                bottom: 5px;
                svg{
                    width: 15px;
                    height: 15px;
                }
            }
            :global {
                .inputGroup {
                    display: flex;
                    gap: 0 32px;
                    flex-wrap: wrap;

                    @include max-screen($stablet) {
                        gap: 0;
                    }

                    .label {
                        width: 280px;
                        color: var(--color-button);
                        font-weight: 600;

                        @include max-screen($stablet) {
                            width: 100%;
                        }
                    }

                    .inputGroup__input {
                        width: calc(100% - 312px);
                        max-width: 512px;

                        @include max-screen($stablet) {
                            width: 100%;
                            max-width: 100%;
                        }

                        input {
                            padding-right: 35px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    .hint,
                    .error {
                        padding-left: 312px;
                        width: 100%;

                        @include max-screen($stablet) {
                            padding-left: 0;
                        }
                    }
                }
            }

            &__wrap {
                position: relative;
                max-width: 825px;

                @include max-screen($stablet) {
                    max-width: 100%;
                }
            }

            &__close {
                position: absolute;
                right: 10px;
                top: 10px;
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;

                @include max-screen($stablet) {
                    top: 37px;
                }

                &.disabled {
                    pointer-events: none;
                    width: 20px;
                    height: 20px;

                    :global .loader-circle {
                        transform: none;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
    }

    &__tableSpace {
        margin-bottom: 15px !important;
    }

    &__modal {
        h2 {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text);
        }

        table {
            width: 100%;
            border-collapse: collapse;
            margin: 24px 0 0;

            tr {

                td,
                th {
                    text-align: left;
                    border: 1px solid var(--color-border);
                    padding: 5px 10px;
                    vertical-align: top;
                }

                th {
                    width: 150px;
                }

                td {
                    width: calc(100% - 150px);
                }
            }
        }
    }

    &__code {
        // background-color: var(--color-black);
        border-radius: 10px;
        padding: 20px;
        // color: var(--color-white);
        width: 100%;

        pre {
            white-space: pre-wrap;
            width: 100%;
            max-height: 300px;
            tab-size: 4;
            font-size: 14px;
            overflow-x: auto;
        }
    }

    &__codeMain {
        border: 1px solid var(--color-border);
        border-top: 0;
        padding: 5px 10px;
        margin-bottom: 15px;

        h2 {

            font-size: 16px;
            border-bottom: 1px solid var(--color-border);
            margin: 0 -10px;
            padding: 0 10px 10px;
        }
    }
  
}

.integrationBtn {
    gap: 16px;
}

.gap{
    gap:8px;
    
 }