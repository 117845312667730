@import '../../assets/scss/variables.scss';

.propertySearch {
    background-color: var(--color-white);

    &__mapSection {
        width: 100%;
    }

    &__map {
        width: 100%;
        transition: all 0.5s;
        &.rightActive {
            width: calc(100% - 850px);
            position: relative;
            @include max-screen($desktop-m) {
                width: calc(100% - 700px);
            }

            @include max-screen($tablet) {
                width: 100%;
            }
        }


    }
}