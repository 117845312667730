.welcomeModal {
    padding: 24px;
    &.tos{
        padding-bottom: 0;
    }

    &__top {
        text-align: center;

        h3 {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text);
            margin-bottom: 4px;
        }

        p {
            font-size: 14px;
            color: var(--color-textLight);

        }
    }

    &__video {
        video {
            width: 100%;
            border-radius: 8px;
        }
    }

    &__info {
        text-align: center;
        margin-top: 18px;

        h2 {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text);

            margin-bottom: 4px;
        }

        p {
            font-size: 14px;
            color: var(--color-textLight);
        }
    }

    &__button {
        margin-top: 32px;
        gap: 12px;

        &.buttonsHalf {
            .button {
                width: calc(50% - 6px);
            }
        }

        .button {
            width: 100%;
           :global{
            .button {
             font-size: 16px;
            }
           }
        }
    }
    &__tos{
        margin: 24px -24px 0 ;
        border-top: 1px solid var(--color-border);
        padding: 16px 24px;
        font-size: 12px;
        color: var(--color-textLight);
        text-align: center;
        span{
            color: var(--color-primary);
            text-decoration: none;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}