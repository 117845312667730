@import '../../../assets/scss/variables.scss';

.smartLeads {
    padding: 120px 16px 92px;
    font-family: $font-poppins;
    background-color: var(--color-white);

    @include max-screen($tablet) {
        padding: 80px 16px;
    }

    &__inner {
        max-width: 1200px;
        margin: 0 auto;
    }

    .header {
        text-align: center;
        margin-bottom: 88px;

        @include max-screen($tablet) {
            margin-bottom: 32px;
        }

        h2 {
            font-size: 64px;
            letter-spacing: -2.56px;
            font-size: 700;
            color: var(--color-black);

            @include max-screen($tablet) {
                font-size: 36px;
                letter-spacing: -1.44px;
            }
        }

        p {
            font-size: 16px;
            font-weight: 300;
            color: var(--color-black);
            opacity: 0.7;
            margin-bottom: 48px;

            @include max-screen($tablet) {
                font-size: 14px;
                opacity: 0.65;
                margin-bottom: 36px;
            }
        }
    }

    .leadsCards {
        gap: 26px;

        @include max-screen($tablet) {
            gap: 24px;
        }

        .property {
            border-radius: 32px;
            background: radial-gradient(140.9% 109.11% at 86.79% 14.8%, #2F0069 0%, #130144 27.95%, #000 100%);
            padding: 46px 46px 0 46px;
            width: calc(100% - 513px);
            min-height: 350px;
            @include max-screen($desktop) {
                padding: 24px 24px 0 24px;
            }
            @include max-screen($tablet) {
                width: 100%;
            }

            p {
                max-width: 185px;

                @include max-screen($tablet) {
                    max-width: 100%;
                }
            }

            .propertyInfo {
                position: relative;

                &__text {
                    position: absolute;

                    @include max-screen($tablet) {
                        position: static;
                        width: 100%;
                    }
                }

                &__info {
                    padding-left: 30px;

                    @include max-screen($desktop) {
                        padding-left: 100px;
                    }

                    @include max-screen($tablet) {
                        width: 100%;
                        padding: 32px 0 0 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    img {
                        max-width: 100%;
                    }
                }
            }
        }

        .skip {
            width: calc(500px - 13px);
            border-radius: 32px;
            padding: 46px;
            background: radial-gradient(99.78% 106.53% at 74.27% 80.19%, #2F0069 0%, #130144 27.95%, #000 100%);
            @include max-screen($desktop) {
                padding: 24px;
            }
            @include max-screen($tablet) {
                width: 100%;
            }

            &__img {
                margin-top: 43px;
                display: flex;
                align-items: flex-end;
                justify-content: center;

                @include max-screen($tablet) {
                    margin-top: 32px;
                }

                img {
                    max-width: 100%;
                }
            }
        }

        .crm {
            border-radius: 32px;
            width: 100%;
            padding: 46px 46px 0 46px;
            position: relative;
            background: radial-gradient(99.78% 106.53% at 74.27% 80.19%, #2F0069 0%, #130144 27.95%, #000 100%);
            @include max-screen($desktop) {
                padding: 24px 24px 0 24px;
            }
          &::after{
            content: "";
            @include background-image(crmBg, svg, bottom left, no-repeat, contain );
            position: absolute;
            width: 100%;
            height: 100%;
            pointer-events: none;
            left: 0;
            top: 0;
          }

            &__info {
                width: 372px;

                @include max-screen($stablet) {
                    width: 100%;
                }

                p {
                    max-width: 300;

                    @include max-screen($stablet) {
                        max-width: 100%;
                    }
                }
            }

            &__img {
                width: calc(100% - 372px);
                display: flex;
                justify-content: flex-end;

                @include max-screen($stablet) {
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    padding-top: 32px;
                }

                img {
                    max-width: 100%;
                }
            }
        }

        .detail {
            width: calc(500px - 13px);
            border-radius: 32px;
            background: radial-gradient(99.78% 106.53% at 74.27% 80.19%, #2F0069 0%, #130144 27.95%, #000 100%);
            padding: 46px;
            @include max-screen($desktop) {
                padding: 24px;
            }
            @include max-screen($tablet) {
                
                width: 100%;
            }

            p {
                max-width: 288px;

                @include max-screen($tablet) {
                    max-width: 100%;
                }
            }

            &__img {
                margin-top: 20px;

                @include max-screen($tablet) {
                    margin-top: 32px;
                    display: flex;
                    justify-content: center;
                }

                img {
                    max-width: 100%;
                    min-width: 100%;
                }
            }
        }

        .infinite {
            padding: 46px;
            width: calc(100% - 513px);
            border-radius: 32px;
            background: radial-gradient(99.78% 106.53% at 74.27% 80.19%, #2F0069 0%, #130144 27.95%, #000 100%);
            gap: 28px;
            @include max-screen($desktop) {
                gap: 10px;
                padding: 24px;
            }
            @include max-screen($tablet) {
               
                width: 100%;
            }
            @include max-screen($mobile) {
                gap: 32px;
            }

            &__left {
                width: 188px;
                @include max-screen($desktop) {
                    width: 150px;
                }
                @include max-screen($mobile) {
                    width: 100%;
                }
                ul {
                    list-style: disc;
                    padding-left: 15px;
                    color: var(--color-white);
                    opacity: 0.8;

                    li {
                        font-weight: 300;
                        font-size: 16px;


                        @include max-screen($desktop) {
                            font-size: 14px;
                        }


                    }
                }
            }

            &__right {
                width: calc(100% - 216px);
                gap: 14px 8px;
                @include max-screen($desktop) {
                    width: calc(100% - 160px);
                }
                @include max-screen($mobile) {
                    width: 100%;
                }
                .infiniteHeder {
                    width: 100%;
                    height: 56px;
                    background: linear-gradient(90deg, #6439FF 5.89%, #1BC8FF 100%);
                    font-size: 16px;
                    text-align: center;
                    font-weight: 400;
                    color: var(--color-white);
                    padding: 16px;
                    border-radius: 55px;
                }
                .infiniteImg{
                    width: 100%;
                }

                .colHalf {
                    width: calc(50% - 4px);
                    .infiniteCard {
                    @include max-screen($mobile) {
                        height: 100px;
                    }
                }
                }

                .colThird {
                    width: calc(33.3% - 6px);

                    .infiniteCard {
                        height: 192px;
                        gap: 36px;
                        @include max-screen($mobile) {
                            gap: 27px;
                            height: 144px;
                        }
                    }
                }

                .infiniteCard {
                    border-radius: 16px;
                    background: rgba(255, 255, 255, 0.08);
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 28px 10px;
                    gap: 8px;
                    height: 100%;

                    h4 {
                        font-size: 32px;
                        font-weight: 700;
                        color: var(--color-white);
                        @include max-screen($mobile) {
                            font-size:24px;
                        }
                    }

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        opacity: 0.6;
                        color: var(--color-white);
                        @include max-screen($mobile) {
                            font-size: 10px;
                        }
                    }

                    h3 {
                        font-size: 14px;
                        font-weight: 400;
                        color: var(--color-white);
                        text-align: center;
                        @include max-screen($mobile) {
                            font-size: 10px;
                        }
                    }

                    figure {
                        @include round-image(58px);
                        background-color: rgba(255, 255, 255, 0.12);
                        color: rgba(155, 132, 247, 1);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include max-screen($mobile) {
                            @include round-image(43px); 
                            min-height: 43px;
                        }

                        img {
                            @include round-image(58px);
                            @include max-screen($mobile) {
                                @include round-image(43px); 
                                min-height: 43px;
                            }
                        }
                    }
                }
            }
        }

        h2 {
            font-size: 32px;
            font-weight: 500;
            color: var(--color-white);
            margin-bottom: 32px;

            @include max-screen($tablet) {
                font-size: 20px;
                margin-bottom: 16px;
            }
        }

        p {
            font-weight: 300;
            font-size: 16px;
            color: var(--color-white);
            opacity: 0.8;

            @include max-screen($desktop) {
                font-size: 14px;
            }

        }

        .mobile {
            display: none;

            @include max-screen($mobile) {
                display: block;
            }
        }

        .desktop {
            @include max-screen($mobile) {
                display: none;
            }
        }
    }
}